import React from 'react';
import { setTitle } from '@util/browser';

const AboutEasterEggPage = () => {
    setTitle('About');

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-two-thirds">
                <h1>About</h1>
                <p>
                    Crafted with ❤️ in Birmingham, Bradford, Canterbury, Croydon, Harrogate, Leeds,
                    London, Manchester, Peterborough and Reading.
                </p>
                <p>
                    This is our small contribution to make things easier for our hard working NHS
                    colleagues 🌈.
                </p>

                <p>
                    Built by Abbie Collier, Arup Banerjee, Babar Mahmood, Connor Avery, Dave Jarvis,
                    Deepika Kakkireni, Dominic Kelly, Gurvinder Gill, Jacob Baker, Jazir Shine, John
                    Moody, Josh Holdsworth, Kaily Newman, Katherine Bennett, Kevin Kuszyk, Kuldip
                    Deu, Leon Delaimy, Luke Weller, Maria Timson, Nick Brown, Paul Fernando, Phillip
                    Stanley, Ramya Subramanian, Satnam Singh, William Wernick, and Zeshan Zoheb.
                </p>
                <p>
                    Thanks to all the NHS England support teams (architecture, assurance, service
                    management, etc), and our friends in DPS core.
                </p>
                <p>And finally, a special thanks to Natalie Pye.</p>
            </div>
        </div>
    );
};

export default AboutEasterEggPage;
