import { setTitle } from '@util/browser';
import React from 'react';

const PageNotFound = props => {
    setTitle('Page Not Found');

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-two-thirds">
                <h1>We can’t find the page you’re looking for</h1>
                <p>If you typed the web address, check it is correct.</p>
                <p>If you pasted the web address, check you copied the entire address.</p>
                <p>
                    Otherwise you may need to{' '}
                    <a href="https://datacollection.sdcs.digital.nhs.uk/datacollection/submission/submit">
                        Login
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

export default PageNotFound;
