import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

import { MapContainer, TileLayer } from 'react-leaflet';

import L from 'leaflet';
import React from 'react';
import { getOrganisationLatLng } from '@services/org-code-geo-coder';

class MapOutput extends React.Component {
    handleMapCreated = map => {
        if (this.props.question.value) {
            const layer = L.geoJSON(this.props.question.value);
            const bounds = layer.getBounds();

            layer.addTo(map);

            if (bounds.isValid()) {
                map.fitBounds(bounds);
            } else {
                this.zoomToOrgCode(map);
            }
        } else {
            this.zoomToOrgCode(map);
        }
    };

    zoomToOrgCode = async map => {
        const latLng = await getOrganisationLatLng(this.props.orgCode);

        if (latLng) {
            map.setView(latLng, 15);
        }
    };

    render() {
        const containerStyle = {
            width: '100%',
            height: '20em'
        };

        const centre = {
            lat: 53,
            lng: -2
        };

        return (
            <MapContainer
                center={centre}
                doubleClickZoom={false}
                dragging={false}
                scrollWheelZoom={false}
                style={containerStyle}
                whenCreated={this.handleMapCreated}
                zoom={5}
                zoomAnimation={false}
                zoomControl={false}
                Fullscreen={true}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </MapContainer>
        );
    }
}

export default MapOutput;
