import { clearTokens, selectAuthState } from 'store/auth.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { Button } from 'nhsuk-react-components';
import React from 'react';
import styles from './logout.module.scss';
import { useNavigate } from 'react-router-dom';

const LogOut = () => {
    const { isLoggedIn } = useAppSelector(selectAuthState);

    const dispatch = useAppDispatch();
    const history = useNavigate();

    const handleLogOutClick = () => {
        dispatch(clearTokens());
        history('/login');
    };

    return (
        isLoggedIn && (
            <div className={'nhsuk-header__menu nhsuk-header__menu--only nhsuk-u-margin-left-3'}>
                <Button target="_self" onClick={handleLogOutClick} className={`${styles.button}`}>
                    Log Out
                </Button>
            </div>
        )
    );
};

export default LogOut;
