import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import Api from '@services/api';
import ClearButton from './clear-button';
import Loader from './loader';
import Results from './results';
import { TextInput } from 'nhsuk-react-components';
import styles from './gmc.module.scss';

const Gmc = forwardRef((props, ref) => {
    const inputRef = useRef(null);

    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [focused, setFocused] = useState(-1);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [readOnly] = useState(false);
    const [resultsVisible, setResultsVisible] = useState(false); // renamed from showResultsDropDown

    useEffect(() => {
        window.addEventListener('click', handleWindowClicked);
        setIsReadOnly(props.question.isReadOnly);

        return () => {
            window.removeEventListener('click', handleWindowClicked);
        };
    }, [props.question.isReadOnly]);

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validate = () => {
        const value = props.question.value ?? '';

        if (props.question.mandatory) {
            if (value.length === 0) {
                setValidationError(`Enter the ${props.question.name}`, false);
            } else {
                setValidationError(undefined, true);
            }
        } else {
            setValidationError(undefined, true);
        }
    };

    const setValidationError = (error, isValid) => {
        setError(error);
        setIsValid(isValid);
    };

    const handleOnInputChange = async e => {
        const { value } = e.target;

        if (value !== props.question.value) {
            handleResultsVisibility(value.length); // renamed from showResultsDropDown
            updateInputValue(props.question.id, value);
        }
    };

    const handleOnInputFocus = async e => {
        handleResultsVisibility(e.target.value.length); // renamed from showResultsDropDown
    };

    const handleResultsVisibility = count => {
        const minCount = 3;
        const isInputActive = inputRef.current === document.activeElement;
        const isReadOnly = readOnly;

        if (count >= minCount && isInputActive && !isReadOnly) {
            setResultsVisible(true); // renamed from showResultsDropDown
            search();
        } else {
            setResultsVisible(false); // renamed from showResultsDropDown
        }
    };

    const updateInputValue = async (questionId, value) => {
        await props.onChange({
            questionId,
            value
        });

        validate();
    };

    const search = async () => {
        setData([]);
        setDataLoading(true);

        try {
            const api = new Api();
            const response = await api.getRefData(inputRef.current.value);

            if (response.data) {
                setData(response.data);
                setDataLoading(false);
                setFocused(-1);
            }
        } catch {
            setDataLoading(false);
            setFocused(-1);
        }
    };

    const handleUpdateResults = async () => {
        populateLinkedFields();
        setData([]);
    };

    const populateLinkedFields = () => {
        const refData = data[focused];
        const linkedQuestions = props.question.linked_questions;

        for (const questionId in linkedQuestions) {
            updateInputValue(linkedQuestions[questionId], refData?.[questionId]);
        }

        updateInputValue(props.question.id, refData?.gmc_ref_no);

        props.handleDisable({
            isReadOnly: refData?.gmc_ref_no ? true : false,
            questionIds: [props.question.id, ...Object.values(linkedQuestions)]
        });
    };

    const clearInputValue = e => {
        e.preventDefault();
        populateLinkedFields();
    };

    const handleOnKeyDown = e => {
        const numFocusableItems = data.length - 1;

        switch (e.key) {
            case 'ArrowDown':
                if (focused < numFocusableItems) {
                    const newFocus = focused + 1;
                    setFocused(newFocus);
                    handleResultsScroll(newFocus);
                }
                break;
            case 'ArrowUp':
                if (focused > 0) {
                    const newFocus = focused - 1;
                    setFocused(newFocus);
                    handleResultsScroll(newFocus);
                }
                break;
            case 'Escape':
                inputRef.current.blur();
                setResultsVisible(false); // renamed from showResultsDropDown
                break;
            case 'Enter':
                e.preventDefault();
                handleUpdateResults();
                break;
            default:
                break;
        }
    };

    const handleResultsScroll = focused => {
        const results = document.querySelector('#results');
        const item = document.querySelector('#results > li');
        if (results) {
            results.scrollTo({
                behavior: 'smooth',
                top: focused * item.getBoundingClientRect().height
            });
        }
    };

    const handleWindowClicked = e => {
        const { type } = e.target;
        if (type !== 'text') {
            setResultsVisible(false); // renamed from showResultsDropDown
        }
    };

    const value = props.question.value ?? '';

    return (
        <div className={`nhsuk-u-clear ${styles.SearchWithResults}`}>
            <TextInput
                className={`${styles.SearchWithResultsInput}`}
                disabled={isReadOnly}
                error={error}
                hint={props.question.hint_text}
                id={props.id}
                inputRef={inputRef}
                label={props.question.name}
                onChange={handleOnInputChange}
                onFocus={handleOnInputFocus}
                onKeyDown={handleOnKeyDown}
                readOnly={readOnly}
                value={value}
                width="10"
                required={props.question.mandatory}
            />
            <Results
                data={data}
                focused={focused}
                handleClick={handleUpdateResults}
                setFocused={setFocused}
                visible={resultsVisible} // renamed from showResultsDropDown
            />
            <ClearButton handleClick={clearInputValue} visible={isReadOnly} />
            <Loader loading={dataLoading} />
        </div>
    );
});

export default Gmc;
