import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import axios from 'axios';

export interface ConfigState {
    apiBaseUrl: string;
    auth: AuthConfig;
    feature_flags: Record<string, unknown>;
    status: 'initial' | 'loading' | 'loaded';
}

export interface AuthConfig {
    serverBaseUrl: string;
    clientId: string;
    sdcsClassicLoginUrl: string;
    idps: Idp[];
}

/* eslint-disable no-unused-vars --
    We should be using @typescript-eslint/no-unused-vars in typescript projects,
    but enabling that makes lots of extra linting errors.  Falling back to this
    approach until we can switch to the typescript version instead.
    https://typescript-eslint.io/docs/linting/troubleshooting/#i-am-using-a-rule-from-eslint-core-and-it-doesnt-work-correctly-with-typescript-code
    See also WBFCD-1658.
*/
export enum Idp {
    Cognito = 'COGNITO',
    Cis2 = 'cis2',
    SdcsCloud = 'sdcscloud'
}
/* eslint-enable */

const initialState: ConfigState = {
    apiBaseUrl: '',
    auth: {
        serverBaseUrl: '',
        clientId: '',
        sdcsClassicLoginUrl: '',
        idps: []
    },
    feature_flags: {},
    status: 'initial'
};

export const fetchConfig = createAsyncThunk('config/fetchConfig', async () => {
    const response = await axios.get(`/config.json`);

    return response.data;
});

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchConfig.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchConfig.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.apiBaseUrl = action.payload.api_base_url;
                state.auth = {
                    serverBaseUrl: action.payload.auth.server_base_url,
                    clientId: action.payload.auth.client_id,
                    sdcsClassicLoginUrl: action.payload.auth.sdcs_classic_login_url,
                    idps: action.payload.auth.idps
                };
                state.feature_flags = action.payload.feature_flags;
            });
    }
});

export const selectStatus = (state: RootState) => state.config.status;
export const selectConfig = (state: RootState) => state.config;

export default configSlice.reducer;
