import { Card } from 'nhsuk-react-components';
import React from 'react';
import { isOpen } from '@util/submission-window';
import moment from 'moment';

const SubmissionWindowInfo = props => {
    const submissionWindow = props.collection.submission_window;

    if (submissionWindow) {
        const now = moment();
        const open = moment(submissionWindow.open_time);
        const close = moment(submissionWindow.close_time);

        if (isOpen(props.collection)) {
            if (submissionWindow.close_time) {
                return (
                    <Card feature>
                        <Card.Content>
                            <Card.Heading>Submission Window</Card.Heading>
                            <Card.Description>
                                The submission window closes on {close.format('Do MMMM YYYY')} at {}
                                {close.format('HH:mm')}.
                            </Card.Description>
                        </Card.Content>
                    </Card>
                );
            }
        }

        if (submissionWindow.open_time && now.isBefore(open)) {
            return (
                <Card feature>
                    <Card.Content>
                        <Card.Heading>Submission Window</Card.Heading>
                        <Card.Description>
                            The submission window opens on {open.format('Do MMMM YYYY')} at {}
                            {open.format('HH:mm')}.
                        </Card.Description>
                    </Card.Content>
                </Card>
            );
        }

        if (submissionWindow.close_time && now.isAfter(close)) {
            return (
                <Card feature>
                    <Card.Content>
                        <Card.Heading>Submission Window</Card.Heading>
                        <Card.Description>
                            The submission window closed on {close.format('Do MMMM YYYY')} at {}
                            {close.format('HH:mm')}.
                        </Card.Description>
                    </Card.Content>
                </Card>
            );
        }
    }

    return null;
};

export default SubmissionWindowInfo;
