import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import axios from 'axios';

export interface AuthState {
    accessToken: string | null;
    isExpired: boolean;
    isLoaded: boolean;
    isLoggedIn: boolean;
    refreshToken: string | null;
    url_slug: string | null;
}

const initialState: AuthState = {
    accessToken: null,
    isExpired: false,
    isLoaded: false,
    isLoggedIn: false,
    refreshToken: localStorage.getItem('refreshToken'),
    url_slug: null
};

export interface ExchangeDalCodeForTokens {
    code: string;
}

export interface ExchangeAuthCodeForTokensArgs {
    authCode: string;
    codeVerifier: string;
}

export const exchangeJwtForTokens = createAsyncThunk(
    'auth/exchangeJwtForTokens',
    async (jwt: string, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;

        const collection = getCollectionUrlSlug();
        const path = collection ? `v2/auth/token-exchange/${collection}` : 'v2/auth/token-exchange';
        const url = new URL(path, state.config.apiBaseUrl).toString();

        const response = await axios.post(url, {
            token: jwt
        });

        return response.data;
    }
);

export const exchangeDalCodeForTokens = createAsyncThunk(
    'auth/exchangeDalCodeForTokens',
    async (code: string, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const tokenUrl = new URL('v2/auth/code-exchange', state.config.apiBaseUrl).toString();

        const params = {
            code: code
        };

        const response = await axios.post(tokenUrl, params);
        return response.data;
    }
);

export const exchangeAuthCodeForTokens = createAsyncThunk(
    'auth/exchangeAuthCodeForTokens',
    async (args: ExchangeAuthCodeForTokensArgs, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const tokenUrl = new URL('oauth2/token', state.config.auth.serverBaseUrl).toString();

        const params = new URLSearchParams({
            client_id: state.config.auth.clientId,
            code_verifier: args.codeVerifier,
            code: args.authCode,
            grant_type: 'authorization_code',
            redirect_uri: new URL('oauth2/callback', window.location.origin).toString()
        });

        const response = await axios.post(tokenUrl, params);

        return response.data;
    }
);

export const refreshAccessToken = createAsyncThunk(
    'auth/refreshAccessToken',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const url = new URL('oauth2/token', state.config.auth.serverBaseUrl).toString();

        const params = new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: state.config.auth.clientId,
            refresh_token: state.auth.refreshToken ?? ''
        });

        const response = await axios.post(url, params);

        return response.data;
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearTokens: state => {
            state.accessToken = null;
            state.isLoggedIn = false;
            state.refreshToken = null;

            localStorage.removeItem('refreshToken');
        }
    },
    extraReducers: builder => {
        builder
            .addCase(exchangeJwtForTokens.fulfilled, (state, action) => {
                state.accessToken = action.payload.access_token;
                state.isLoaded = true;
                state.isLoggedIn = true;
                state.refreshToken = action.payload.refresh_token;

                localStorage.setItem('refreshToken', action.payload.refresh_token);
            })
            .addCase(exchangeJwtForTokens.rejected, state => {
                state.accessToken = null;
                state.isLoaded = true;
                state.isLoggedIn = false;
                state.refreshToken = null;
            })
            .addCase(refreshAccessToken.fulfilled, (state, action) => {
                state.accessToken = action.payload.access_token;
                state.isLoaded = true;
                state.isLoggedIn = true;
            })
            .addCase(refreshAccessToken.rejected, state => {
                if (state.isLoggedIn) {
                    state.isExpired = true;
                }

                state.accessToken = null;
                state.isLoaded = true;
                state.isLoggedIn = false;
            })
            .addCase(exchangeDalCodeForTokens.fulfilled, (state, action) => {
                state.accessToken = action.payload.access_token;
                state.isLoaded = true;
                state.isLoggedIn = true;
                state.refreshToken = action.payload.refresh_token;
                state.url_slug = action.payload.url_slug;
            })
            .addCase(exchangeDalCodeForTokens.rejected, state => {
                state.accessToken = null;
                state.isLoaded = true;
                state.isLoggedIn = false;
                state.refreshToken = null;
            })
            .addCase(exchangeAuthCodeForTokens.fulfilled, (state, action) => {
                state.accessToken = action.payload.access_token;
                state.isLoggedIn = true;
                state.refreshToken = action.payload.refresh_token;
            })
            .addCase(exchangeAuthCodeForTokens.rejected, state => {
                state.accessToken = null;
                state.isLoggedIn = false;
                state.refreshToken = null;
            });
    }
});

function getCollectionUrlSlug() {
    const url = new URL(window.location.href);

    return url.pathname.split('/')[1];
}

export const { clearTokens } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;
