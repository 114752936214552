import { WarningSummary } from '@form';
import React from 'react';
import { isEmpty } from 'lodash';

const WarningSummarySection = ({ warningList }) =>
    !isEmpty(warningList) && (
        <WarningSummary ariaLabelledby="warning-summary-title" role="alert" tabIndex={-1}>
            <WarningSummary.Title id="warning-summary-title">
                Check your responses
            </WarningSummary.Title>
            <WarningSummary.Body>
                <p>The following questions are incomplete:</p>
            </WarningSummary.Body>
            <WarningSummary.List>
                {warningList.map(({ id, name }) => (
                    <WarningSummary.Item key={id}>{name}</WarningSummary.Item>
                ))}
            </WarningSummary.List>
        </WarningSummary>
    );

export default WarningSummarySection;
