/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Api from '@services/api';
import { Card } from 'nhsuk-react-components';
import { SubmissionWindowInfo } from '@submission';
import { isOpen } from '@util/submission-window';
import { useParams, useNavigate } from 'react-router-dom';

const CollectionDefault = props => {
    const [buttonText, setButtonText] = useState(undefined);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const isSubmissionWindowOpen = isOpen(props.collection);
    const [organisationData, setOrganisationData] = useState([]);
    const [orgCode, setOrgCode] = useState(undefined);
    const [submissionId, setSubmissionId] = useState(undefined);
    const [submissionStatus, setSubmissionStatus] = useState(undefined);
    const navigate = useNavigate();
    const params = useParams();

    let orgsHtmlOptions;

    const fetchData = async () => {
        try {
            const api = new Api();
            const response = await api.getOrganisations();
            setOrganisationData(response);
            loadSubmission(response);
            setIsLoading(false); // Move loading state update here
        } catch (error) {
            setOrganisationData(null);
            setIsLoading(false); // Move loading state update here
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array ensures useEffect runs only once

    const update = orgs => {
        let newButtonText = 'Start';
        let newIsCompleted = false;
        let newSubmissionId;
        let newSubmissionStatus;

        const orgsData = orgs ? orgs.orgs : organisationData.orgs;
        const { name: orgName, org_type_code: orgTypeCode } = orgsData.find(
            x => x.code === props.orgCode
        );

        if (props.collection.org_submit_mode === 'single') {
            const submission = orgsData.find(x => x.code === props.orgCode)?.submissions[0];
            newSubmissionId = submission?.id;
            newSubmissionStatus = submission?.status;

            const allowEditCompletedSubmission =
                props.collection.submission_window && isSubmissionWindowOpen;

            switch (submission?.status) {
                case 'not-started':
                    newButtonText = 'Start';
                    break;

                case 'in-progress':
                    newButtonText = 'Continue';
                    break;

                case 'completed':
                    newButtonText = allowEditCompletedSubmission ? 'Edit' : undefined;
                    newIsCompleted = allowEditCompletedSubmission ? false : true;
                    break;

                default:
                    throw new Error('Unknown submission status');
            }
        }

        setButtonText(newButtonText);
        setIsCompleted(newIsCompleted);
        setSubmissionId(newSubmissionId);
        setSubmissionStatus(newSubmissionStatus);
        setOrgCode(props.orgCode);

        props.onOrgCodeChange({
            orgTypeCode,
            orgCode: props.orgCode,
            orgName,
            submissionId: newSubmissionId
        });
    };

    const navigateToFirstSectionOfForm = () => {
        const section = props.collection.form.sections[0];
        const path = `/${params.collection}/${section.id}`;
        navigate(path);
    };

    const navigateToViewSubmissionPage = submissionId => {
        const path = `/${params.collection}/submission/${submissionId}`;
        navigate(path);
    };

    const loadSubmissionData = submissionId => {
        props.onLoadSubmission({
            orgCode: props.orgCode,
            submissionId
        });
    };

    const loadSubmission = orgs => {
        if (props.collection.org_submit_mode === 'single-per-submitter') {
            const { id, status } = orgs.orgs[0].submissions[0];

            loadSubmissionData(id);

            if (status === 'not-started') {
                navigateToFirstSectionOfForm();
            } else {
                navigateToViewSubmissionPage(id);
            }

            return;
        }

        update(orgs);

        if (!isCompleted) {
            navigateToFirstSectionOfForm();

            if (submissionId) {
                props.onLoadSubmission({
                    orgCode: props.orgCode,
                    submissionId
                });
            }
        }
    };

    if (isLoading) {
        return 'Loading...';
    }

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
                <h1>{props.collection.name}</h1>

                {props.collection.home_page === 'default' && (
                    <SubmissionWindowInfo collection={props.collection} />
                )}

                {isSubmissionWindowOpen && (
                    <>
                        {isCompleted && (
                            <Card feature>
                                <Card.Content>
                                    <Card.Heading>Collection Completed</Card.Heading>
                                    <Card.Description>
                                        This submission has already been successfully completed.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default CollectionDefault;
