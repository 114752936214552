export const themeConfig = {
    color: {
        border: '#4c6272',
        primary: '#555555'
    },
    select: {
        css: 'color: #000; font-weight: 400; font-size: 1rem; line-height: 1.5; border-radius: 0px; width: 400px'
    },
    control: {
        boxShadowColor: 'rgba(255, 235, 59)',
        backgroundColor: 'rgba(255, 255, 255)',
        focusedBorderColor: 'rgb(55, 55, 0)',
        focusedCss: 'border-color: ##4c6272; border-width: 4px;',
        borderRadius: '0px',
        borderColor: '##4c6272',
        borderWidth: '2px',
        height: '40px',
        width: '400px'
    },
    icon: {
        color: '#005eb8',
        hoverColor: '#A6A6A6',
        padding: '0 0px',
        clear: {
            width: '28px',
            height: '28px',
            animation: 'FADE_IN_KEYFRAMES 0.25s ease-in-out',
            transition: 'color 0.2s ease-out'
        },
        caret: {
            size: '0px',
            transition: 'transform 0.3s ease-in-out, color 0.2s ease-out'
        }
    },
    menu: {
        option: {
            selectedColor: '#fff',
            selectedBgColor: '#555555',
            focusedBgColor: 'rgba(66, 135, 245, 0.725)'
        }
    }
};
