import ReactHtmlParser from 'react-html-parser';
import { forwardRef } from 'react';

const TextBlock = forwardRef((props, ref) => {
    var html = atob(props.question.html);
    if (props.question.hidden) {
        html = atob('');
    }
    return ReactHtmlParser(html);
});

export default TextBlock;
