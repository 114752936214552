import setGender from '@util/set-gender';
import styles from './result.module.scss';

const Result = ({ focused, handleClick, index, item, setFocused }) => {
    const { given_name, gmc_ref_no, other_names, surname } = item;
    const gender = setGender(item.gender);

    const onMouseOver = () => {
        setFocused(index);
        highlight();
    };

    const highlight = () => {
        return focused === index ? styles.ResultActive : '';
    };

    return (
        <li className={styles.Result}>
            <button
                className={`${styles.ResultItem} ${highlight()}`}
                onClick={handleClick}
                onMouseOver={onMouseOver}
                onFocus={onMouseOver}
            >
                <div>
                    {given_name} {other_names} {surname}
                </div>
                <div>
                    <span className={styles.gender}>{gender}</span>
                    <span className={styles.gmc_ref_num}>{gmc_ref_no}</span>
                </div>
            </button>
        </li>
    );
};

export default Result;
