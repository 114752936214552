import { TextInput } from 'nhsuk-react-components';

const FilterByText = ({ submissionSummary }) => {
    return submissionSummary
        .filter(question => {
            return question.type === 'text';
        })
        .map(question => {
            return (
                <TextInput
                    question={question.id}
                    id={question.id}
                    label={question.display}
                    key={question.id}
                />
            );
        });
};

export default FilterByText;
