import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import './styles.scss';

import * as serviceWorker from './serviceWorker';

import {
    AboutEasterEggPage,
    AccessibilityPage,
    ErrorPage,
    Login,
    Oauth2CallbackPage,
    PageNotFound
} from '@pages';
import { AppBreadcrumb, AppFooter, AppHeader, ConfigProvider, LoginDal } from '@components/app';
import { AuthProvider, PrivateRoute } from 'auth';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';

import AppLayout from '@components/app-layout';
import { Home } from '@components/home';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { store } from 'app/store';

function App() {
    const [organisation, setOrganisation] = useState({});
    const [collection, setCollection] = useState({});
    const [home, setHome] = useState({});
    const { help_url } = collection;

    return (
        <div className="App">
            <AppHeader helpUrl={help_url} />
            <AppBreadcrumb
                collection={collection}
                setHome={setHome}
                setOrganisation={setOrganisation}
                setCollection={setCollection}
            />
            <div id="container">
                <section>
                    <div className="nhsuk-width-container-fluid">
                        <div className="nhsuk-main-wrapper" id="maincontent" role="main">
                            <ConfigProvider>
                                <AuthProvider>
                                    <Routes>
                                        <Route path="/m/:code" element={<LoginDal />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/.about" element={<AboutEasterEggPage />} />
                                        <Route
                                            path="/oauth2/callback"
                                            element={<Oauth2CallbackPage />}
                                        />
                                        <Route
                                            path="/accessibility"
                                            element={<AccessibilityPage />}
                                        />
                                        <Route path="/error" element={<ErrorPage />} />
                                        <Route path="/:collection" element={<PrivateRoute />}>
                                            <Route
                                                index
                                                element={
                                                    <AppLayout
                                                        setCollection={setCollection}
                                                        organisation={organisation}
                                                        setOrganisation={setOrganisation}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/:collection/:section/:index?"
                                                element={
                                                    <AppLayout
                                                        setCollection={setCollection}
                                                        organisation={organisation}
                                                        setOrganisation={setOrganisation}
                                                        path="/:section/:index?"
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/:collection/completed"
                                                element={
                                                    <AppLayout
                                                        setCollection={setCollection}
                                                        organisation={organisation}
                                                        setOrganisation={setOrganisation}
                                                        path="/completed"
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/:collection/summary"
                                                element={
                                                    <AppLayout
                                                        setCollection={setCollection}
                                                        organisation={organisation}
                                                        setOrganisation={setOrganisation}
                                                        path="/summary"
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/:collection/submission/:submission_id"
                                                element={
                                                    <AppLayout
                                                        setCollection={setCollection}
                                                        organisation={organisation}
                                                        setOrganisation={setOrganisation}
                                                        path="submission/:submission_id"
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/:collection/submission/:submission_id/transfer"
                                                element={
                                                    <AppLayout
                                                        setCollection={setCollection}
                                                        organisation={organisation}
                                                        setOrganisation={setOrganisation}
                                                        path="submission/:submission_id/transfer"
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/:collection/submission/:submission_id/delete"
                                                element={
                                                    <AppLayout
                                                        setCollection={setCollection}
                                                        organisation={organisation}
                                                        setOrganisation={setOrganisation}
                                                        path="submission/:submission_id/delete"
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route path="/" element={<PrivateRoute />}>
                                            <Route
                                                index
                                                element={
                                                    <Home
                                                        setCollection={setCollection}
                                                        collection={collection}
                                                        organisation={organisation}
                                                        setOrganisation={setOrganisation}
                                                        home={home}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route path="*" element={<PageNotFound />} />
                                    </Routes>
                                </AuthProvider>
                            </ConfigProvider>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter helpUrl={help_url} />
        </div>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
