import { useEffect, useState } from 'react';

import { ErrorPage } from '@pages';
import { Navigate } from 'react-router';
import { exchangeAuthCodeForTokens } from 'store/auth.slice';
import { useAppDispatch } from 'app/hooks';

/* eslint-disable no-unused-vars --
    We should be using @typescript-eslint/no-unused-vars in typescript projects,
    but enabling that makes lots of extra linting errors.  Falling back to this
    approach until we can switch to the typescript version instead.
    https://typescript-eslint.io/docs/linting/troubleshooting/#i-am-using-a-rule-from-eslint-core-and-it-doesnt-work-correctly-with-typescript-code
    See also WBFCD-1658.
*/
enum Render {
    Initial,
    Loading,
    Error,
    Ok
}
/* eslint-enable no-unused-vars */

export default function Oauth2CallbackPage() {
    const [render, setRender] = useState(Render.Initial);
    const dispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            if (render === Render.Initial) {
                const savedState = sessionStorage.getItem('pkce_state');
                const savedCodeVerifier = sessionStorage.getItem('code_verifier');
                const url = new URL(window.location.href);
                const code = url.searchParams.get('code');
                const state = url.searchParams.get('state');
                const error = url.searchParams.get('error');

                if (error === null && state === savedState && code && savedCodeVerifier) {
                    setRender(Render.Loading);

                    const result = await dispatch(
                        exchangeAuthCodeForTokens({
                            authCode: code,
                            codeVerifier: savedCodeVerifier
                        })
                    );

                    if (result.meta.requestStatus === 'fulfilled') {
                        setRender(Render.Ok);
                    } else {
                        setRender(Render.Error);
                    }
                } else {
                    setRender(Render.Error);
                }
            }
        })();
    }, [dispatch, render]);

    switch (render) {
        case Render.Ok:
            return <Navigate to="/" />;

        case Render.Error:
            return (
                <ErrorPage error_description="There was problem with your login attempt, Please try again later" />
            );

        default:
            return <>Loading...</>;
    }
}
