import { isEmpty } from 'lodash';

const ApplyFilters = ({ setSearch, search, submissionSummary }) => {
    const filterValues = {};

    const handleTextFilter = question => {
        const questionId = question.id;
        const domElement = document.querySelector(`#${questionId}`);
        const valueTrimmed = domElement.value.trim();
        if (valueTrimmed) {
            if (
                search &&
                search[questionId] &&
                !search[questionId].find(term => term === valueTrimmed)
            ) {
                filterValues[questionId] = search[questionId].concat([valueTrimmed]);
            } else if (question && isEmpty(search[questionId])) {
                filterValues[questionId] = [valueTrimmed];
            }
        }
        domElement.value = '';
    };

    const handleRadioAndSelectFilter = question => {
        if (search?.[question.id]) {
            filterValues[question.id] = search[question.id];
        }

        const domElement = document.querySelectorAll(`[name='${question.id}']`);

        Object.values(domElement)
            .filter(({ checked }) => checked)
            .forEach(checkedItems => {
                filterValues[question.id] = filterValues[question.id] ?? [];
                checkedItems.checked = false;

                if (filterValues[question.id].indexOf(checkedItems.value) === -1) {
                    filterValues[question.id].push(checkedItems.value);
                }
            });
    };

    const filterTypes = [
        { radio: handleRadioAndSelectFilter },
        { select: handleRadioAndSelectFilter },
        { text: handleTextFilter }
    ];

    const setFilterValues = () => {
        submissionSummary.forEach(question =>
            filterTypes.find(item => (item[question.type] ? item[question.type](question) : null))
        );
    };

    const handleApplyFilter = () => {
        setFilterValues();
        setSearch({ ...search, ...filterValues });
    };

    const handleMouseClick = event => {
        handleApplyFilter();
        event.currentTarget.blur();
    };

    const handleKeyPress = event => {
        event.preventDefault();
        if (event.key === 'Enter') {
            handleApplyFilter();
        }
    };

    return (
        <button
            className="nhsuk-button"
            type="submit"
            onClick={handleMouseClick}
            onKeyPress={handleKeyPress}
        >
            Apply Filters
        </button>
    );
};

export default ApplyFilters;
