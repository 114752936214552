import React, {
    ChangeEvent,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';

import { InputWidth } from 'nhsuk-react-components/dist/esm/util/types/NHSUKTypes';
import { NameBoxProps } from '@form-controls';
import { TextInput } from 'nhsuk-react-components';

const NameBox: React.FC<NameBoxProps> = forwardRef((props, ref) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const isMounted = useRef<boolean>(true);

    useEffect(() => {
        // ComponentDidMount equivalent
        setIsReadOnly(props.question.isReadOnly);

        // Cleanup function for componentWillUnmount
        return () => {
            isMounted.current = false;
        };
    }, [props.question.isReadOnly]);

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validate = () => {
        const value = getValue();
        const questionName = props.question.name;

        if (props.question.mandatory && value.length === 0) {
            setError(`${questionName} is required`);
            setIsValid(false);
            return;
        }

        validateMinMaxName(value, questionName);
    };

    const validateMinMaxName = (value: string, questionName: string) => {
        const { max_length, min_length } = props.question;
        const isMinNumber = typeof min_length === 'number';
        const isMaxNumber = typeof max_length === 'number';
        const validNamePattern = /^[A-Za-z]+([ '-]?[A-Za-z]+|[,][ ]?[A-Za-z]+)*$/.test(value);

        if (
            value.length > 0 &&
            isMaxNumber &&
            isMinNumber &&
            (value.length > max_length || value.length < min_length)
        ) {
            setError(`${questionName} must be between ${min_length} and ${max_length} characters`);
            setIsValid(false);
            return;
        }

        if (value.length > 0 && isMinNumber && value.length < min_length) {
            setError(`${questionName} must be greater than or equal to ${min_length} characters`);
            setIsValid(false);
            return;
        }

        if (value.length > 0 && isMaxNumber && value.length > max_length) {
            setError(`${questionName} must be less than or equal to ${max_length} characters`);
            setIsValid(false);
            return;
        }

        if (value.length > 0 && !validNamePattern) {
            setError(
                `The ${questionName} must start and end with a letter. Numbers and special characters are not allowed apart from a single apostrophe ('), hyphen (-), comma (,), or space ( ) between two letters.`
            );
            setIsValid(false);
            return;
        }

        setError(undefined);
        setIsValid(true);
    };

    const getValue = (): string => {
        return props.question.value ?? '';
    };

    const getWidth = (): InputWidth => {
        const validWidthValues: InputWidth[] = [2, 3, 4, 5, 10, 20, 30];
        return validWidthValues.includes(props.question.width || 10)
            ? props.question.width || 10
            : 10;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange({
            questionId: props.question.id,
            value: event.target.value
        });
    };

    const handleBlur = () => {
        validate();
    };

    return (
        <TextInput
            id={props.id}
            label={props.question.name}
            hint={props.question.hint_text}
            error={error}
            width={getWidth()}
            value={getValue()}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isReadOnly}
            required={props.question.mandatory}
        />
    );
});

export default NameBox;
