import { Pagination, PaginationResults } from '@table';
import React from 'react';
import styles from './pagination-container.module.css';

const PaginationContainer = ({ handlePageClick, paging, results }) => {
    return (
        <div className="nhsuk-grid-column-full">
            <div className="nhsuk-grid-row">
                {paging.isPaginationRequired ? (
                    <div className={styles.Pagination}>
                        <Pagination handlePageClick={handlePageClick} paging={paging}></Pagination>
                    </div>
                ) : null}
                <div className={styles.PaginationResults}>
                    <PaginationResults
                        pageStartIndex={paging.pageStartIndex + 1}
                        pageEndIndex={paging.pageEndIndex + 1}
                        numberOfResults={results.length}
                    ></PaginationResults>
                </div>
            </div>
        </div>
    );
};

export default PaginationContainer;
