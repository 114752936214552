import _ from 'lodash';

function enrichFormData(formData, collection) {
    const data = _.cloneDeep(formData);

    let index = 0;
    const multipleSectionCounter = collection.form.sections.map(x => ({
        id: x.id,
        allow_multiple: x.allow_multiple,
        index: 1
    }));

    data.forEach(section => {
        section.index = index;
        if (section.allow_multiple) {
            const counter = multipleSectionCounter.find(x => section.id === x.id);
            section.multipleSectionIndex = counter.index;

            counter.index++;
        }

        index++;
    });

    return data;
}

export { enrichFormData };
