import { CollectionDefault, CollectionSummary } from '@collection';

import React from 'react';

class CollectionHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            render: this.props.collection.home_page
        };
    }

    handleOnCreateNewRecord = () => {
        this.setState({
            render: 'default'
        });
    };

    render() {
        switch (this.state.render) {
            case 'submission-summary':
                return (
                    <CollectionSummary
                        collection={this.props.collection}
                        onCreateNewRecord={this.handleOnCreateNewRecord}
                        onLoadSubmission={this.props.onLoadSubmission}
                        onOrgCodeChange={this.props.onOrgCodeChange}
                        orgCode={this.props.orgCode}
                    />
                );

            default:
                return (
                    <CollectionDefault
                        collection={this.props.collection}
                        onLoadSubmission={this.props.onLoadSubmission}
                        onOrgCodeChange={this.props.onOrgCodeChange}
                        orgCode={this.props.orgCode}
                    />
                );
        }
    }
}

export default CollectionHome;
