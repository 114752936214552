import { Table } from 'nhsuk-react-components';
import React from 'react';

class OpeningTimesOutput extends React.Component {
    getValue = () => {
        return (
            this.props.question.value ?? {
                monday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                tuesday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                wednesday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                thursday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                friday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                saturday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                },
                sunday: {
                    am_open: '',
                    am_close: '',
                    pm_open: '',
                    pm_close: '',
                    comments: ''
                }
            }
        );
    };

    tableCellStyle = {
        paddingRight: '0'
    };

    noBorderBottomTextCenter = {
        borderBottom: '0',
        textAlign: 'center'
    };

    noBorderBottom = {
        borderBottom: '0'
    };

    defaultBorderBottom = {
        textAlign: 'center'
    };

    commentsRow = {
        paddingTop: '0'
    };

    days = [
        { key: 'monday', display: 'Mon' },
        { key: 'tuesday', display: 'Tue' },
        { key: 'wednesday', display: 'Wed' },
        { key: 'thursday', display: 'Thu' },
        { key: 'friday', display: 'Fri' },
        { key: 'saturday', display: 'Sat' },
        { key: 'sunday', display: 'Sun' }
    ];

    render() {
        const value = this.getValue();

        const formatDayOutput = value => {
            return value === 'closed' ? 'Closed' : value;
        };

        return (
            <Table>
                <Table.Head>
                    <Table.Row>
                        <Table.Cell style={this.tableCellStyle}></Table.Cell>
                        <Table.Cell style={this.tableCellStyle}>AM Open</Table.Cell>
                        <Table.Cell style={this.tableCellStyle}>AM Close</Table.Cell>
                        <Table.Cell style={this.tableCellStyle}>PM Open</Table.Cell>
                        <Table.Cell style={this.tableCellStyle}>PM Close</Table.Cell>
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {this.days.map((day, index) => {
                        const dayValue = value[day.key];

                        const borderStyleControl = dayValue.comments
                            ? this.noBorderBottomTextCenter
                            : this.defaultBorderBottom;

                        return (
                            <React.Fragment key={index}>
                                <Table.Row>
                                    <Table.Cell
                                        style={dayValue.comments ? this.noBorderBottom : null}
                                    >
                                        <strong>{day.display}</strong>
                                    </Table.Cell>
                                    <Table.Cell style={borderStyleControl}>
                                        {formatDayOutput(dayValue.am_open)}
                                    </Table.Cell>
                                    <Table.Cell style={borderStyleControl}>
                                        {formatDayOutput(dayValue.am_close)}
                                    </Table.Cell>
                                    <Table.Cell style={borderStyleControl}>
                                        {formatDayOutput(dayValue.pm_open)}
                                    </Table.Cell>
                                    <Table.Cell style={borderStyleControl}>
                                        {formatDayOutput(dayValue.pm_close)}
                                    </Table.Cell>
                                </Table.Row>
                                {dayValue.comments ? (
                                    <Table.Row>
                                        <Table.Cell style={this.commentsRow} colSpan="5">
                                            {dayValue.comments}
                                        </Table.Cell>
                                    </Table.Row>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }
}

export default OpeningTimesOutput;
