import { Button, Fieldset, Radios } from 'nhsuk-react-components';
import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { setTitle } from '@util/browser';

const OrgSwitcher = ({ handleSetCollectionRender, permissions, organisation, setOrganisation }) => {
    setTitle('NHS England Forms Collections');

    const [options, setOptions] = useState([]);
    const [error, setError] = useState(false);
    const [isOrgSelected, setOrgSelected] = useState(false);

    const handleContinue = event => {
        event.preventDefault();
        event.target.blur();
        if (!isOrgSelected) {
            setError('Choose an organisation');
        } else {
            handleSetCollectionRender();
        }
    };

    useEffect(() => {
        async function buildOrgData() {
            const handleOrganisation = org => {
                return event => {
                    setOrgSelected(true);
                    setOrganisation(org);
                    setError(false);
                };
            };
            const orgHtmlOptions = permissions.map(org => {
                const { org_code, org_name } = org;
                return (
                    <Radios.Radio
                        key={org_code}
                        id={org_code}
                        value={org_code}
                        onClick={handleOrganisation(org)}
                        error={error ? error : undefined}
                    >
                        {org_name} ({org_code})
                    </Radios.Radio>
                );
            });
            setOptions(orgHtmlOptions);
        }
        buildOrgData();
    }, [error, setError, setOrganisation, permissions]);

    return (
        !isEmpty(options) && (
            <>
                <Fieldset>
                    <Fieldset.Legend>Select Your Organisation</Fieldset.Legend>
                    <Radios name="home_org_code_name" id="home_org_code_id" error={error}>
                        {options}
                    </Radios>
                </Fieldset>
                <Button className="nhsuk-u-margin-left-2" onClick={handleContinue}>
                    Continue
                </Button>
            </>
        )
    );
};

export default OrgSwitcher;
