import React, {
    ChangeEvent,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';

import { InputWidth } from 'nhsuk-react-components/dist/esm/util/types/NHSUKTypes';
import { OdsLookupProps } from './ ods-lookup.interface'; // Adjusted import path assuming correct module path
import { TextInput } from 'nhsuk-react-components';
import { debounce } from 'lodash';
import { getOrganisation } from '../../../services/ods-api';

const OdsLookup: React.FC<OdsLookupProps> = forwardRef((props, ref) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [orgcode, setOrgcode] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(false);
    const _isMounted = useRef<boolean>(true);

    useEffect(() => {
        // Cleanup function for componentWillUnmount
        return () => {
            _isMounted.current = false;
            validate.cancel(); // Cancel any pending debounced validations
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*     useEffect(() => {
        // Initialize orgcode from props if it exists
        const { value } = props.question;
        if (value) {
            setOrgcode(value.org_code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.question.value]); */

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validate = useCallback(
        debounce(async () => {
            const { mandatory } = props.question;

            if (mandatory && (!orgcode || orgcode.trim() === '') && _isMounted.current) {
                setError('Enter a valid ODS organisation code');
                setIsValid(false);
                return;
            }

            if (orgcode && _isMounted.current) {
                try {
                    const odsCode = await getOrganisation(orgcode);

                    if (!odsCode) {
                        setError('Enter a valid ODS organisation code');
                        setIsValid(false);
                        return;
                    }

                    // Update parent component with valid org code
                    props.onChange({
                        questionId: props.question.id,
                        value: {
                            org_name: odsCode.Name,
                            org_code: orgcode
                        }
                    });

                    // Clear error and set validity
                    setError(undefined);
                    setIsValid(true);
                } catch (error) {
                    // console.error('Error fetching organisation:', error);
                    setError('Error fetching organisation details');
                    setIsValid(false);
                }
            }
        }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [orgcode, props]
    );

    const getWidth = (): InputWidth => {
        const validWidthValues: InputWidth[] = [2, 3, 4, 5, 10, 20, 30];
        // Ensure the specified width is valid or default to 10
        return validWidthValues.includes(props.question.width || 10)
            ? props.question.width || 10
            : 10;
    };

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        // Convert input value to uppercase
        setOrgcode(evt.target.value.toUpperCase());
    };

    const handleBlur = () => {
        // Trigger debounced validation on blur
        validate();
    };

    return (
        <TextInput
            id={props.id}
            label={props.question.name}
            hint={props.question.hint_text}
            error={error}
            value={orgcode}
            width={getWidth()}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
});

export default OdsLookup;
