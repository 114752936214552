const ClearFilters = ({ setSearch }) => {
    const handleClearFilters = event => {
        event.preventDefault();
        setSearch({});
    };

    return (
        <a href="/" onClick={handleClearFilters} className="nhs-link">
            Clear Filters
        </a>
    );
};

export default ClearFilters;
