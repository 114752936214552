import React, { forwardRef } from 'react';
import ReactHtmlParser from 'react-html-parser';

const FormSectionContainer = forwardRef((props, ref) => {
    const headerHtml = props.sectionSpec.header ? atob(props.sectionSpec.header) : undefined;

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
                <h1>{props.sectionSpec.name}</h1>
                {ReactHtmlParser(headerHtml)}
                {props.children}
            </div>
        </div>
    );
});

export default FormSectionContainer;
