import ClipLoader from 'react-spinners/ClipLoader';
import styles from './loader.module.scss';

const Loader = ({ loading }) => {
    if (!loading) {
        return null;
    }

    return (
        <span className={styles.Loader}>
            <ClipLoader color={'#005eb8'} loading={loading} size={20} />
        </span>
    );
};

export default Loader;
