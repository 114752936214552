import React from 'react';

const CheckboxOutput = props => {
    const value = props.value ?? props.question.value ?? [];
    const selected = props.question.options.filter(x => value.includes(x.key));

    return (
        <React.Fragment>
            {selected.map((x, index) => {
                return (
                    <React.Fragment key={index}>
                        {x.value}
                        <br />
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default CheckboxOutput;
