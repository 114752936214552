import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Textarea } from 'nhsuk-react-components';

const TextArea = forwardRef((props, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validate = () => {
        const { value, name, mandatory, min_length, max_length } = props.question;
        let error;
        let isValid = true;

        if (mandatory && !value) {
            error = `Enter the ${name}`;
            isValid = false;
        } else if ((min_length || max_length) && value) {
            isValid = validateMinMax(min_length, max_length, value, name);
        } else {
            error = undefined;
        }

        setError(error);
        setIsValid(isValid);
    };

    const validateMinMax = (min, max, value, questionName) => {
        if (max && min && (value.length > max || value.length < min)) {
            setError(`${questionName} must be between ${min} and ${max} characters`);
            return false;
        } else if (min && value.length < min) {
            setError(`${questionName} must be minimum ${min} characters in length`);
            return false;
        } else if (max && value.length > max) {
            setError(`${questionName} exceeds the max length of ${max} characters`);
            return false;
        }
        return true;
    };

    const handleChange = async event => {
        await props.onChange({
            questionId: props.question.id,
            value: event.target.value
        });

        validate();
    };

    const { value, name, hint_text, id, rows, mandatory } = props.question;

    return (
        <Textarea
            id={id}
            label={name}
            hint={hint_text}
            error={error}
            rows={rows || 5}
            name={name}
            value={value || ''}
            onChange={handleChange}
            onBlur={handleChange}
            required={mandatory}
        />
    );
});

export default TextArea;
