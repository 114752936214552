import React, { useState } from 'react';

import ApplyFilters from './apply-filters';
import ClearFilters from './clear-filters';
import FilterByCheckbox from './filter-by-checkbox';
import FilterByText from './filter-by-text';
import SelectedFilter from './selected-filter';
import styles from './filters.module.css';
import useFilter from './hooks/useFilter';

const Filters = ({
    className,
    isFilterOpen,
    setSubmissionData,
    submissionsDataCache,
    submissionSummary,
    collectionID
}) => {
    const [search, setSearch] = useState(
        sessionStorage.getItem('search') && sessionStorage.getItem('collectionID') === collectionID
            ? JSON.parse(sessionStorage.getItem('search'))
            : {}
    );

    useFilter(search, setSubmissionData, submissionsDataCache, collectionID);
    if (!isFilterOpen) {
        return null;
    }

    return (
        <div className={className}>
            <div className={`${styles.filtersHeader} nhsuk-u-padding-3`}>
                <p className="nhsuk-u-font-weight-bold nhsuk-u-margin-bottom-0">Filter</p>
            </div>
            <div className={`${styles.filtersSelected} nhsuk-u-padding-3`}>
                <h4 className="nhsuk-heading-s">Selected filters</h4>
                <div className={styles.filtersClearFilter}>
                    <ClearFilters setSearch={setSearch} />
                </div>
                <div className="nhsuk-u-margin-top-2">
                    <SelectedFilter
                        search={search}
                        clearSearch={setSearch}
                        submissionSummary={submissionSummary}
                    />
                </div>
            </div>
            <div className={`${styles.filters} nhsuk-u-padding-3`}>
                <ApplyFilters
                    setSearch={setSearch}
                    search={search}
                    submissionSummary={submissionSummary}
                />
                <FilterByText submissionSummary={submissionSummary} />
                <FilterByCheckbox submissionSummary={submissionSummary} />
            </div>
        </div>
    );
};

export default Filters;
