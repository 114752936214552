import { FormQuestionSection, MultipleSectionAdd, MultipleSectionAddAnother } from '@form';
import React, { useEffect, useState } from 'react';
import { getNextSection, getRequestedDataIndex } from '@util/multiple-sections';
import { useLocation, useParams } from 'react-router-dom';

import { PageNotFound } from '@pages';
import { createFutureRequiredQuestionsFromQuestionsAndProps } from '@util/warning-summary';

const FormSection = props => {
    const params = useParams();
    const location = useLocation();

    const [state, setState] = useState({
        isFirstSection: undefined,
        multipleSectionCount: undefined,
        nextPath: undefined,
        questions: undefined,
        render: undefined,
        sectionSpec: undefined,
        showSaveAndExitButton: undefined,
        showSaveAndSkipButton: undefined,
        showSaveAndGoToSummaryButton: undefined,
        futureRequiredQuestions: undefined
    });

    useEffect(() => {
        const update = props.collection.form ? true : false;

        if (update) {
            const sectionSpecs = props.collection.form.sections;
            const sectionId = params.section;
            const collectionUrlSlug = props.collection.url_slug;
            const sectionSpecIndex = sectionSpecs.findIndex(x => x.id === sectionId);
            const sectionSpec = sectionSpecs.find(x => x.id === sectionId);
            if (!sectionSpec) {
                setState(prevState => ({ ...prevState, render: 'not-found' }));
                return;
            }

            const { nextPath, nextSectionId } = getNextSection(
                sectionSpec,
                sectionSpecs,
                props.data,
                collectionUrlSlug
            );

            const indexParam = params.index;
            const index = indexParam ? Number(indexParam) : undefined;
            const dataIndex = getRequestedDataIndex(props.data, sectionId, index);
            const dataSection = props.data[dataIndex] || { questions: [] };
            const questions = dataSection.questions;

            const futureRequiredQuestions = createFutureRequiredQuestionsFromQuestionsAndProps(
                questions,
                props
            );

            let render;
            if (sectionSpec.allow_multiple && !index) {
                render = 'multiple-section-add';
            } else if (sectionSpec.allow_multiple && location.hash === '#add-another') {
                render = 'multiple-section-add-another';
            } else {
                render = 'form-questions';
            }

            setState({
                isFirstLoad: dataSection.isFirstLoad,
                isFirstSection: sectionSpecIndex === 0 ? true : false,
                multipleSectionCount: props.data.filter(x => x.id === sectionSpec.id).length,
                nextPath: nextPath,
                nextSectionId,
                questions: questions,
                render: render,
                sectionSpec: sectionSpec,
                showSaveAndExitButton: props.collection.show_save_and_exit_button,
                showSaveAndSkipButton: props.collection.show_save_and_skip_to_summary_button,
                showSaveAndGoToSummaryButton: props.collection.show_save_and_go_to_summary_button,
                futureRequiredQuestions
            });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.collection, props.data, params, location.hash]);

    const enrichQuestions = questions => {
        questions.forEach(question => {
            if (!question.ref) {
                question.ref = React.createRef();
            }
        });
        return questions;
    };

    switch (state.render) {
        case 'not-found':
            return <PageNotFound />;
        case 'form-questions':
            return (
                <FormQuestionSection
                    collection={props.collection}
                    isFirstLoad={state.isFirstLoad}
                    isFirstSection={state.isFirstSection}
                    multipleSectionCount={state.multipleSectionCount}
                    nextPath={state.nextPath}
                    nextSectionId={state.nextSectionId}
                    onConditionalReveal={props.onConditionalReveal}
                    onCopyAnswer={props.onCopyAnswer}
                    onQuestionChange={props.onQuestionChange}
                    onRemoveSection={props.onRemoveSection}
                    onResetForm={props.onResetForm}
                    onSectionSubmit={props.onSectionSubmit}
                    onSkipSection={props.onSkipSection}
                    onSkipSections={props.onSkipSections}
                    onSubmissionIdChange={props.onSubmissionIdChange}
                    orgCode={props.orgCode}
                    questions={enrichQuestions(state.questions)}
                    sectionSpec={state.sectionSpec}
                    showSaveAndExitButton={state.showSaveAndExitButton}
                    showSaveAndGoToSummaryButton={state.showSaveAndGoToSummaryButton}
                    showSaveAndSkipButton={state.showSaveAndSkipButton}
                    handleDisable={props.handleDisable}
                    futureRequiredQuestions={state.futureRequiredQuestions}
                    submissionId={props.submissionId}
                />
            );
        case 'multiple-section-add':
            return (
                <MultipleSectionAdd
                    collection={props.collection}
                    nextPath={state.nextPath}
                    onAddMultipleSection={props.onAddMultipleSection}
                    sectionSpec={state.sectionSpec}
                />
            );
        case 'multiple-section-add-another':
            return (
                <MultipleSectionAddAnother
                    nextPath={state.nextPath}
                    onAddMultipleSection={props.onAddMultipleSection}
                    sectionSpec={state.sectionSpec}
                />
            );
        default:
            return null;
    }
};

export default FormSection;
