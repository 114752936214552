import { Link } from 'react-router-dom';
import React from 'react';
import { setTitle } from 'util/browser';

const SessionExpired = () => {
    setTitle('Session Expired');
    sessionStorage.clear();

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-two-thirds">
                <h1>Sorry, you'll need to start again</h1>
                <p>You'll need to start again because you've not used this service for a while.</p>
                <p>We do this for your security.</p>
                <Link to="/login" className="nhsuk-button">
                    Start again
                </Link>
            </div>
        </div>
    );
};

export default SessionExpired;
