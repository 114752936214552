import axios from 'axios';
import { getOrganisationPostCode } from './ods-api';

async function getOrganisationLatLng(orgCode) {
    const postCode = await getOrganisationPostCode(orgCode);

    if (postCode) {
        return lookupPostCodeLatLong(postCode);
    }

    return null;
}

async function lookupPostCodeLatLong(postCode) {
    try {
        const response = await axios.get(`https://api.getthedata.com/postcode/${postCode}`);

        if (response.data.status === 'match') {
            return {
                lat: response.data.data.latitude,
                lng: response.data.data.longitude
            };
        }
        // eslint-disable-next-line no-empty
    } catch (error) {}

    return null;
}

export { getOrganisationLatLng };
