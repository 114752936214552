import {
    BranchSite,
    Checkbox,
    CopyAnswer,
    Date,
    Gmc,
    Map,
    NHSNumber,
    NINumber,
    NameBox,
    NumberBox,
    OdsLookup,
    OpeningTimes,
    Radio,
    Select,
    TextArea,
    TextBlock,
    TextBox,
    Time,
    Total
} from '@form-controls';
import React, { forwardRef } from 'react';

const FormQuestions = forwardRef((props, ref) => {
    const {
        question,
        onQuestionChange,
        isFirstLoad,
        orgCode,
        onConditionalReveal,
        questions,
        onCopyAnswer,
        handleDisable
    } = props;
    const formProps = {
        key: props.question.id,
        onChange: onQuestionChange,
        question: question,
        ref: ref
    };
    const renderQuestionComponent = () => {
        switch (question.type) {
            case 'branch-site':
                return (question.ref.current = (
                    <BranchSite {...formProps} allowChanges={isFirstLoad} orgCode={orgCode} />
                ));
            case 'text-block':
                return (question.ref.current = <TextBlock key={question.id} question={question} />);
            case 'checkbox':
                return (
                    <Checkbox
                        {...formProps}
                        onConditionalReveal={onConditionalReveal}
                        questions={questions}
                    />
                );
            case 'date':
                return (question.ref.current = <Date {...formProps} />);
            case 'time':
                return (question.ref.current = <Time {...formProps} />);
            case 'map':
                return (question.ref.current = <Map {...formProps} orgCode={orgCode} />);
            case 'nhs-number':
                return (question.ref.current = <NHSNumber {...formProps} />);
            case 'number':
                return (question.ref.current = <NumberBox {...formProps} />);
            case 'open-close-times':
                return (question.ref.current = <OpeningTimes {...formProps} />);
            case 'radio':
                return (question.ref.current = (
                    <Radio
                        {...formProps}
                        onConditionalReveal={onConditionalReveal}
                        questions={questions}
                    />
                ));
            case 'select':
                return (question.ref.current = question.ref.current = <Select {...formProps} />);
            case 'text':
                return (question.ref.current = <TextBox {...formProps} questions={questions} />);
            case 'textarea':
                return (question.ref.current = <TextArea {...formProps} />);
            case 'copy-answer':
                return (question.ref.current = (
                    <CopyAnswer {...formProps} onCopyAnswer={onCopyAnswer} />
                ));
            case 'ni-number':
                return (question.ref.current = <NINumber {...formProps} />);
            case 'name-box':
                return (question.ref.current = <NameBox {...formProps} />);
            case 'gmc':
                return (question.ref.current = (
                    <Gmc {...formProps} handleDisable={handleDisable} />
                ));
            case 'total':
                return (question.ref.current = (
                    <Total {...formProps} questions={questions} onCopyAnswer={onCopyAnswer} />
                ));
            case 'ods-lookup':
                return (question.ref.current = <OdsLookup {...formProps} />);
            default:
                return (question.ref.current = <TextBox {...formProps} />);
        }
    };

    return renderQuestionComponent();
});

export default FormQuestions;
