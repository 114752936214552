import { getMoment } from '@util/date';

function getSubmissionSectionPayload(questions) {
    return questions
        .filter(question => question.includeInApiPayload)
        .map(question => {
            return {
                id: question.id,
                ...(question.isReadOnly && { isReadOnly: question.isReadOnly }),
                value: parseQuestionValue(question)
            };
        });
}

function parseQuestionValue(question) {
    switch (question.type) {
        case 'branch-site':
            return question.value ? question.value.org_code : null;

        case 'date':
            return question.value ? getMoment(question.value).toISOString() : null;

        default:
            return question.value;
    }
}
export { getSubmissionSectionPayload };
