import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { selectAuthState } from 'store/auth.slice';
import { useAppSelector } from 'app/hooks';

export default function PrivateRoute() {
    const { isLoggedIn } = useAppSelector(selectAuthState);

    if (isLoggedIn) {
        return <Outlet />;
    } else {
        return <Navigate to="/login" />;
    }
}
