export const safeAccesFullName = data => {
    const basicInformation = data.find(wp => wp.id === 'basic-information');
    if (basicInformation) {
        const firstName = basicInformation.questions.find(qn => qn.id === 'first-name');
        const lastName = basicInformation.questions.find(qn => qn.id === 'last-name');
        if (firstName && lastName) {
            return [firstName.value, lastName.value].join(' ');
        } else return '';
    }
};
