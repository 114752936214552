import React, { useEffect } from 'react';
import { exchangeJwtForTokens, refreshAccessToken, selectAuthState } from 'store/auth.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import Cookies from 'js-cookie';
import { SessionExpired } from '@pages';
import { useLocation } from 'react-router-dom';

interface AuthProviderProps {
    children: React.ReactElement;
}

let refreshTokenTimer: ReturnType<typeof setInterval>;

export default function AuthProvider({ children }: AuthProviderProps) {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const { isExpired, isLoaded, isLoggedIn } = useAppSelector(selectAuthState);

    useEffect(() => {
        (async () => {
            if (
                !isLoaded &&
                !location.pathname.startsWith('/m/') &&
                location.pathname !== '/oauth2/callback'
            ) {
                const jwt = Cookies.get('RedirectToken');

                if (jwt) {
                    await dispatch(exchangeJwtForTokens(jwt));

                    Cookies.remove('RedirectToken');
                } else {
                    await dispatch(refreshAccessToken());
                }
            }
        })();
    }, [dispatch, isLoaded, location.pathname]);

    useEffect(() => {
        (async () => {
            if (isLoggedIn) {
                if (!refreshTokenTimer) {
                    refreshTokenTimer = setInterval(async () => {
                        await dispatch(refreshAccessToken());
                    }, 600000);
                }
            } else {
                if (refreshTokenTimer) {
                    clearInterval(refreshTokenTimer);
                }
            }
        })();
    }, [dispatch, isLoggedIn]);

    if (location.pathname === '/oauth2/callback') {
        return children;
    }

    if (isExpired && location.pathname !== '/login') {
        return <SessionExpired />;
    }

    if (isLoaded || location.pathname.startsWith('/m/')) {
        return children;
    }

    return <>Loading...</>;
}
