import moment from 'moment';

function getMoment(value) {
    const args = {
        year: Number(value.year),
        month: Number(value.month) - 1,
        day: Number(value.day)
    };

    return moment.utc(args);
}

function getCurrentMoment() {
    return moment.utc();
}

function getMinMoment(offset) {
    var rightNow = moment();
    var backThen = rightNow.subtract(offset, 'years');
    return backThen;
}

export { getCurrentMoment, getMoment, getMinMoment };
