import React from 'react';
import { setTitle } from '@util/browser';

const AccessibilityPage = () => {
    setTitle('Accessibility');

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
                <h1>
                    Accessibility Statement for the Data Processing Service (DPS) Forms Platform
                </h1>
                <p>
                    This accessibility statement applies to DPS Forms Platform and the data
                    collections that are used on the platform.
                </p>
                <p>
                    This website is run by NHS England. We want as many people as possible to be
                    able to use this website. This means you should be able to:
                </p>
                <ul>
                    <li>use your web browser to change colours, contrast levels and fonts</li>
                    <li>zoom in up to 300% without the text spilling off the screen</li>
                    <li>navigate most of the website using just a keyboard</li>
                    <li>navigate most of the website using speech recognition software</li>
                    <li>
                        listen to most of the website using a screen reader (including the most
                        recent versions of JAWS, NVDA and VoiceOver)
                    </li>
                </ul>
                <p>We also try to make the website text as simple as possible to understand.</p>
                <p>
                    Some of our content is technical, and we use technical terms where there is no
                    easier wording we could use without changing what the text means.
                </p>
                <p>
                    <a href="https://mcmw.abilitynet.org.uk/" rel="nofollow">
                        AbilityNet
                    </a>{' '}
                    has advice on making your device easier to use if you have a disability.
                </p>
                <h2>How accessible this website is</h2>
                <p>We know some parts of this website are not fully accessible:</p>
                <ul>
                    <li>
                        This website only works with JavaScript enabled and is not fully accessible
                        to screen readers or speech recognition software.
                    </li>
                </ul>
                <h2>Feedback and contact information</h2>
                <p>
                    Email{' '}
                    <a href="mailto:ssd.nationalservicedesk@nhs.net" rel="nofollow">
                        ssd.nationalservicedesk@nhs.net
                    </a>{' '}
                    or alternatively call the Customer Service Function on 0300 303 5035 if you have
                    a query about using this website or an accessibility problem not listed on this
                    statement.
                </p>
                <p>Reporting accessibility problems with this website</p>
                <p>
                    We're always looking to improve the accessibility of this website. If you find
                    any problems not listed on this page or think we’re not meeting accessibility
                    requirements, contact: our service desk by email{' '}
                    <a href="mailto:ssd.nationalservicedesk@nhs.net" rel="nofollow">
                        ssd.nationalservicedesk@nhs.net
                    </a>{' '}
                    or alternatively call the Customer Service Function on 0300 303 5035.
                </p>
                <h2>Enforcement procedure</h2>
                <p>
                    If you contact us with a complaint and you are not happy with our response{' '}
                    <a href="https://www.equalityadvisoryservice.com/" rel="nofollow">
                        contact the Equality Advisory and Support Service (EASS)
                    </a>
                    . The Equality and Human Rights Commission (EHRC) is responsible for enforcing
                    the Public Sector Bodies (Websites and Mobile Applications) (No. 2)
                    Accessibility Regulations 2018 (the "accessibility regulations").
                </p>
                <p>Technical information about this website’s accessibility</p>
                <p>
                    NHS England are committed to making this website accessible, in accordance with
                    the Public Sector Bodies (Websites and Mobile Applications) (No. 2)
                    Accessibility Regulations 2018.
                </p>
                <h3>Compliance status</h3>
                <p>
                    This website is not compliant with the{' '}
                    <a href="https://www.w3.org/TR/WCAG21/" rel="nofollow">
                        Web Content Accessibility Guidelines version 2.1 AA standard
                    </a>
                    . The non-compliances and areas under review are listed below.
                </p>
                <h2>Non-accessible content</h2>
                <p>The content listed below is non-accessible for the following reasons.</p>
                <h3>Non-compliance with the accessibility regulations</h3>
                <h4>Perceivable</h4>
                <p>
                    Information and user interface components must be presentable to users in ways
                    they can perceive.
                </p>
                <ul>
                    <li>
                        Not perceivable if JavaScript is disabled - We have suggested an HTML
                        fallback is used. This has been raised with ADIA and NHSD Chief Architect
                        for review.
                    </li>
                    <li>
                        Header navigation links disappear when zooming to 300% - 1.4.4 Resize text
                        (Level AA)
                    </li>
                </ul>
                <ul>
                    <li>
                        Radio button background colour could not be determined due to a pseudo
                        element 4.3 – Contrast (Minimum) (Level AA)
                    </li>
                    <li>
                        Colour contrast issue when on Forms landing page and shown a list of the
                        organisations the user can submit for - 4.3 – Contrast (Minimum) (Level AA)
                    </li>
                </ul>
                <h4>Operable</h4>
                <p>User interface components and navigation must be operable.</p>
                <ul>
                    <li>
                        not operable if JavaScript is disabled - We have suggested an HTML fallback
                        is used. This has been raised with ADIA and NHSD Chief Architect for review
                    </li>
                    <li>
                        select your organisation radio button inoperable using keyboard - 2.1.1
                        Keyboard accessible (Level A)
                    </li>
                    <li>
                        header navigation links within the form are inoperable when using a keyboard
                        - 2.1.1 Keyboard accessible (Level A)
                    </li>
                    <li>
                        skip to content links inoperable when using a keyboard - 2.4.1 Bypass blocks
                        (Level A)
                    </li>
                    <li>
                        link not announced on Patient details, site code - 2.4.4 Link Purpose in
                        context (Level A)
                    </li>
                    <li>
                        wrong link announced on the Summary page, for site code of treatment - 2.4.4
                        Link Purpose in context (Level A)
                    </li>
                    <li>non-descriptive links - 2.4.4 Link Purpose in context (Level A)</li>
                </ul>
                <h4>Understandable</h4>
                <p>Information and the operation of user interface must be understandable.</p>
                <ul>
                    <li>
                        not understandable if JavaScript is disabled - We have suggested an HTML
                        fallback is used. This has been raised with ADIA and NHSD Chief Architect
                        for review
                    </li>
                    <li>
                        error identification on Patient details mandatory fields - 3.3.1 Error
                        identification (Level A)
                    </li>
                    <li>
                        incorrect form element identified, all select lists - 3.2.2 On input (Level
                        A)
                    </li>
                    <li>
                        incorrect input type identified on Device details, type of device - spin
                        button - 3.2.2 On input (Level A)
                    </li>
                    <li>
                        alert announced without error on page load - 3.3.1 Error identification
                        (Level A)
                    </li>
                </ul>
                <ul>
                    <li>
                        Multiple label elements is not widely supported in assistive technologies –
                        We need to ensure the time component label contains all necessary
                        information – 3.3.2 Labels or instructions (Level A)
                    </li>
                </ul>
                <h4>Robust</h4>
                <p>
                    Content must be robust enough that it can be interpreted by a wide variety of
                    user agents, including assistive technologies.
                </p>
                <ul>
                    <li>
                        not robust if JavaScript is disabled - We have suggested an HTML fallback is
                        used. This has been raised with ADIA and NHSD Chief Architect for review.
                    </li>
                    <li>
                        large sections of page content not announced on Revision or removal - 4.1.1
                        Parsing (Level A)
                    </li>
                </ul>
                <h2>What we're doing to improve accessibility</h2>
                <p>
                    Creating an accessible service is a team effort. We want our teams to make
                    accessible services by:
                </p>
                <ul>
                    <li>considering accessibility at the start of their project, and throughout</li>
                    <li>making accessibility the whole team's responsibility</li>
                    <li>researching with people with access needs</li>
                    <li>carrying out regular accessibility audits and testing</li>
                    <li>
                        designing and building to level AA of the Web Content Accessibility
                        Guidelines (WCAG 2.1).
                    </li>
                </ul>
                <p>
                    We are making sure that accessibility issues highlighted in this statement are
                    being prioritised and fixed. We are prioritising accessibility remedial work in
                    all new development and improvement projects.
                </p>
                <h2>Preparation of this accessibility statement</h2>
                <p>
                    This statement was prepared on 15 February 2022. It was last reviewed on 25th
                    March 2022.
                </p>
            </div>
        </div>
    );
};

export default AccessibilityPage;
