import React, { useEffect } from 'react';
import { exchangeDalCodeForTokens, selectAuthState } from '../../../store/auth.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';

import { ErrorPage } from '@pages';

// interface LoginDalParams {
//     code: string;
// }

export const LoginDal: React.FC = () => {
    const params = useParams();
    const code = params.code;

    const history = useNavigate();
    const dispatch = useAppDispatch();
    const { isLoaded, isLoggedIn, url_slug } = useAppSelector(selectAuthState);

    useEffect(() => {
        if (!code) {
            // console.error('Code parameter is missing');
            return;
        }

        (async () => {
            await dispatch(exchangeDalCodeForTokens(code));
        })();
    }, [code, dispatch]);

    useEffect(() => {
        if (url_slug) {
            history(`/${url_slug}`);
        }
    }, [history, url_slug]);

    return (
        <>
            {!isLoaded && <>Loading...</>}
            {isLoaded && !isLoggedIn && <ErrorPage />}
        </>
    );
};
