import { WarningCallout } from 'nhsuk-react-components';

export function NoPermissions() {
    return (
        <WarningCallout>
            <WarningCallout.Label>Permissions</WarningCallout.Label>
            <p>You have successfully logged in to the Data Collections platform.</p>
            <p>
                Please call the National Service Desk to request permissions for the collection(s)
                you would like to access.
            </p>
            <p>National Service Desk telephone: 0300 303 5035.</p>
        </WarningCallout>
    );
}
