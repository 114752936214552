import _, { isEmpty } from 'lodash';

import { getSkippableQuestions } from '@services/form-data-section';

const getRequestedDataIndex = (data, sectionId, requestedIndex) => {
    if (requestedIndex === undefined) {
        return data.findIndex(x => x.id === sectionId);
    }

    let index = 0;
    let sectionIndex = 1;

    for (const section of data) {
        if (section.id === sectionId) {
            if (requestedIndex === sectionIndex) {
                return index;
            }
            sectionIndex++;
        }

        index++;
    }

    return null;
};

const getRequestedNewMultipleSectionDataIndex = (data, sectionSpecs, sectionId, requestedIndex) => {
    const sectionSpecIndex = sectionSpecs.findIndex(x => x.id === sectionId);
    const dataIndex = getRequestedDataIndex(data, sectionId, requestedIndex);

    // TODO: Review, properly test and think this logic through
    if (dataIndex === null) {
        // We don't already have the section, so we need to figure out where to put it

        const dataSectionLastIndex = _.findLastIndex(data, x => x.id === sectionId);

        // If the section is the first one in the form, and we don't already have one of these sections,
        // we need to add a new section at the beginning, otherwise add it after the the ones
        // we already have
        if (sectionSpecIndex === 0) {
            return dataSectionLastIndex === -1 ? 0 : dataSectionLastIndex + 1;
        }
        // The section is not the first in the form, so add the new section after the existing ones, or
        // after the previous section if we don't have any of these ones yet.
        else {
            if (dataSectionLastIndex === -1) {
                const prevSectionSpec = sectionSpecs[sectionSpecIndex - 1];
                const prevSectionLastIndex = _.findLastIndex(
                    data,
                    x => x.id === prevSectionSpec.id
                );

                // TODO: Need to do some recursive looping here incase we don't have the previous section either
                return prevSectionLastIndex + 1;
            }

            return dataSectionLastIndex + 1;
        }
    } else {
        // We found the section, so return it's data index
        return dataIndex;
    }
};

function getNextSectionPath(sectionSpec, sectionSpecs, dataSections, collectionUrlSlug) {
    const nextSectionSpec = getNextSectionSpec(sectionSpec, sectionSpecs, dataSections);

    return nextSectionSpec
        ? getSectionPath(nextSectionSpec, dataSections, collectionUrlSlug)
        : `/${collectionUrlSlug}/summary`;
}

function getNextSection(sectionSpec, sectionSpecs, dataSections, collectionUrlSlug) {
    const nextSectionSpec = getNextSectionSpec(sectionSpec, sectionSpecs, dataSections);

    return {
        nextSectionId: nextSectionSpec ? nextSectionSpec.id : 'summary',
        nextPath: getNextSectionPath(sectionSpec, sectionSpecs, dataSections, collectionUrlSlug)
    };
}

function getNextSectionSpec(sectionSpec, sectionSpecs, dataSections) {
    const sectionSpecId = sectionSpec.id;
    const sectionSpecIndex = sectionSpecs.findIndex(({ id }) => id === sectionSpecId);
    const questions = getSkippableQuestions(dataSections.filter(({ id }) => id === sectionSpecId));

    if (!isEmpty(questions)) {
        const [selectedQuestion] = dataSections
            .filter(({ id }) => id === sectionSpecId)
            .flatMap(({ questions }) => questions);

        if (selectedQuestion?.value) {
            const { next_section } = selectedQuestion.options.find(
                ({ key }) => key === selectedQuestion.value
            );
            if (next_section) {
                if (next_section === 'summary') {
                    return;
                }
                const nextSectionSpec = sectionSpecs.find(({ id }) => id === next_section);
                if (nextSectionSpec) {
                    return nextSectionSpec;
                }
            }
        }
        return sectionSpecs
            .filter((sectionSpec, i) => sectionSpecIndex < i)
            .find(({ id }) => dataSections.find(dataSection => dataSection.id === id));
    }

    return sectionSpecs
        .filter((sectionSpec, i) => sectionSpecIndex < i)
        .find(
            ({ allow_multiple, id }) =>
                dataSections.find(dataSection => dataSection.id === id)?.show || allow_multiple
        );
}

function getFirstSectionPath(sectionSpec, dataSections, collectionUrlSlug) {
    return getSectionPath(sectionSpec, dataSections, collectionUrlSlug);
}

function getSectionPath(sectionSpec, dataSections, collectionUrlSlug) {
    if (sectionSpec.allow_multiple) {
        const multipleSectionCount = dataSections.filter(x => x.id === sectionSpec.id).length;

        if (multipleSectionCount) {
            return `/${collectionUrlSlug}/${sectionSpec.id}/1`;
        }
    }
    return `/${collectionUrlSlug}/${sectionSpec.id}`;
}

export {
    getFirstSectionPath,
    getNextSectionPath,
    getNextSection,
    getRequestedDataIndex,
    getRequestedNewMultipleSectionDataIndex
};
