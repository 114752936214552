import React from 'react';
const TransferContext = React.createContext(null);

const useTransfer = () => React.useContext(TransferContext);

const TransferProvider = ({ value, children }) => {
    return <TransferContext.Provider value={value}>{children}</TransferContext.Provider>;
};

export { TransferProvider, useTransfer };
