export async function getUrlSafeSHA256Hash(input: string): Promise<string> {
    const hash = await getSHA256Hash(input);

    return hashToUrlSafeBase64String(hash);
}

export async function getSHA256Hash(input: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const encoded = encoder.encode(input);

    return await crypto.subtle.digest('SHA-256', encoded);
}

export function hashToUrlSafeBase64String(arrayBuffer: ArrayBuffer): string {
    const items = new Uint8Array(arrayBuffer);
    const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '');
    const base64 = btoa(stringifiedArrayHash);

    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
