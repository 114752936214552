import Result from './result';
import styles from './results.module.scss';

const Results = ({ data, focused, handleClick, setFocused, visible }) => {
    if (!data?.length > 0 || !visible) {
        return null;
    }

    const items = data.map((item, index) => {
        return (
            <Result
                focused={focused}
                handleClick={handleClick}
                index={index}
                item={item}
                key={index}
                setFocused={setFocused}
            />
        );
    });

    return (
        <ul id="results" className={styles.Results}>
            {items}
        </ul>
    );
};

export default Results;
