import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { TextInput } from 'nhsuk-react-components';
import nhsNumberValidator from 'nhs-number-validator';

const NHSNumber = forwardRef((props, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.question.value]);

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validate = () => {
        const value = getValue();
        const questionName = props.question.name;

        let errorMessage;
        let isValid = true;

        if (props.question.mandatory || value.length > 0) {
            const validNHSNumberPattern =
                /^[0-9]{3}[^a-zA-Z0-9]?[0-9]{3}[^a-zA-Z0-9]?[0-9]{4}$/.test(value);
            const numbersOnly = (value.match(/\d/g) || []).join('');
            const containsLetters = /[a-zA-Z]/.test(value);

            if (validNHSNumberPattern) {
                if (!nhsNumberValidator.validate(numbersOnly)) {
                    errorMessage = `The ${questionName} is not valid`;
                    isValid = false;
                }
            } else {
                isValid = false;

                if (numbersOnly.length === 0) {
                    errorMessage = `The ${questionName} is required`;
                } else if (numbersOnly.length < 10) {
                    errorMessage = `The ${questionName} is too short`;
                } else if (numbersOnly.length > 10) {
                    errorMessage = `The ${questionName} is too long`;
                } else if (containsLetters) {
                    errorMessage = `The ${questionName} should not contain letters`;
                } else {
                    errorMessage = `The ${questionName} is not valid`;
                }
            }
        }

        setError(errorMessage);
        setIsValid(isValid);
    };

    const getValue = () => {
        return props.question.value ?? '';
    };

    const handleChange = async event => {
        await props.onChange({
            questionId: props.question.id,
            value: event.target.value
        });

        validate();
    };

    const value = getValue();

    return (
        <TextInput
            id={props.id}
            inputMode="numeric"
            label={props.question.name}
            hint={props.question.hint_text}
            error={error}
            type="text"
            width="10"
            value={value}
            onChange={handleChange}
            onBlur={handleChange}
            required={props.question.mandatory}
        />
    );
});

export default NHSNumber;
