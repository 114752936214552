import { getMoment } from '@util/date';
import moment from 'moment';

const DateOutput = props => {
    const format = getDateFormat(props);

    if (props.question && props.question.value) {
        return getMoment(props.question.value).format(format);
    }

    if (props.isoString) {
        return moment.utc(props.isoString).format(format);
    }

    return '';
};

function getDateFormat(props) {
    return props.format === 'long' ? 'Do MMMM YYYY' : 'D MMM YYYY';
}

export default DateOutput;
