function createFullWarningListFromQuestions(questions) {
    let warningList;
    // make the warning list template with all required types
    const fullWarningListTemplate = questions
        .filter(
            ({ required, includeInApiPayload, value, hidden }) =>
                required &&
                includeInApiPayload &&
                (hidden === false || hidden === undefined) &&
                (value === '' || value === null || value === undefined)
        )
        .map(({ id, name, value, required, type }) => ({
            id,
            name,
            value,
            type,
            question_id: required.question_id,
            required_value: required.value,
            required_type: typeof required
        }));
    // find the list of questions from the full list that have a required map
    const dependencyWarningList = fullWarningListTemplate.filter(
        ({ type, required_type }) => type === 'gmc' && required_type === 'object'
    );
    const gmcWarning = shouldValidateGmc(dependencyWarningList, questions);
    if (dependencyWarningList.length > 0) {
        // get the list of required items that have values
        const warningListWithValues = questions
            .filter(
                ({ required, includeInApiPayload, value }) =>
                    typeof required === 'boolean' && includeInApiPayload && value
            )
            .map(({ id, name, value }) => ({ id, name, value }));
        // filter these against the dependency list
        const filteredDependencyList = warningListWithValues
            .filter(({ id, value }) =>
                dependencyWarningList.some(
                    ({ question_id, required_value }) =>
                        id === question_id && value === required_value
                )
            )
            // add the value we're going to check for from the required dependency list
            .map(listItems => ({
                uid: dependencyWarningList.find(
                    ({ required_value }) => listItems.value === required_value
                ).id,
                ...listItems
            }));

        if (gmcWarning === true) {
            warningList = fullWarningListTemplate.filter(
                ({ id, type, required_type }) =>
                    (type === 'gmc' &&
                        required_type === 'object' &&
                        filteredDependencyList.find(({ uid }) => id === uid)) ||
                    required_type === 'boolean' ||
                    required_type === 'object'
            );
        } else {
            warningList = fullWarningListTemplate.filter(
                ({ id, type, required_type }) =>
                    (type === 'gmc' &&
                        required_type === 'object' &&
                        filteredDependencyList.find(({ uid }) => id === uid)) ||
                    required_type === 'boolean'
            );
        }
    } else {
        warningList = fullWarningListTemplate;
    }
    return warningList?.map(({ id, name }) => ({ id, name }));
}

function createSummaryWarningListFromData(data) {
    const questions = data.filter(({ show }) => show).flatMap(({ questions }) => questions);
    return createFullWarningListFromQuestions(questions);
}

function createSectionWarningListFromProps({ futureRequiredQuestions, questions }) {
    const questionList =
        futureRequiredQuestions?.length > 0 ? questions.concat(futureRequiredQuestions) : questions;
    return createFullWarningListFromQuestions(questionList);
}

function createFutureRequiredQuestionsFromQuestionsAndProps(questions, props) {
    const conditionallyRequiredQuestions = questions
        .filter(({ type, required }) => type === 'gmc' && typeof required === 'object')
        .map(({ required }) => required);
    return conditionallyRequiredQuestions.length > 0
        ? props.data
              .filter(
                  ({ id, show }) =>
                      conditionallyRequiredQuestions.some(({ section_id }) => id === section_id) &&
                      show
              )
              .flatMap(({ questions }) => questions)
              .filter(({ id, value }) =>
                  conditionallyRequiredQuestions.some(
                      ({ question_id }) => id === question_id && value
                  )
              )
        : null;
}

/*  This function checks to see if user group the user selected matches the user group in the required tag found on the GMC object
    For example, as of 31/10/2024 nhs-workforce-staff-details has the following required tag attached to GP:
    "required": {
                  "section_id": "additional-information",
                  "question_id": "staff-group-gp",
                  "value": "gp"
                }
    This means that that the GMC field will only be validated if the user selected the GP option in the User Group dropdown.
    This function could be modfied to accept multiple different options being valid rather than just GP.
*/
function shouldValidateGmc(gmcField, submission) {
    const requiredDependency = submission.filter(({ id }) => id === gmcField[0]?.question_id);
    if (requiredDependency[0]) {
        if (gmcField[0].required_value === requiredDependency[0].value) {
            return true;
        } else {
            return false;
        }
    }
}

export {
    createSummaryWarningListFromData,
    createSectionWarningListFromProps,
    createFutureRequiredQuestionsFromQuestionsAndProps
};
