import { Button, CloseIcon } from 'nhsuk-react-components';

import React from 'react';
import { nanoid } from 'nanoid';
import styles from './selected-filter.module.css';

const SelectedFilter = ({ clearSearch, search, submissionSummary }) => {
    if (Object.entries(search).length < 1) {
        return null;
    }

    const getFilterValueFromSummary = (filterKey, value) => {
        const displayName = submissionSummary.filter(item => {
            return item.id === filterKey;
        });
        if (displayName[0] !== undefined) {
            if (displayName[0].options) {
                const key = displayName[0].options
                    .filter(item => {
                        return item.key === value;
                    })
                    .map(i => {
                        return i.value;
                    });
                return key[0];
            }
        }

        return value;
    };

    const getDisplayLabelFromSummary = filterKey => {
        const displayName = submissionSummary.filter(item => {
            return item.id === filterKey;
        });
        if (displayName[0] !== undefined) return displayName[0].display;
    };

    const generateFilterButtons = (filterKey, filterValues) => {
        let buttons;
        const selectedButtons = filterValues.map(filterValue => {
            const displayValue = getFilterValueFromSummary(filterKey, filterValue);
            buttons = (
                <div key={nanoid()}>
                    <Button
                        className={`${styles.selectedFilterButton} nhsuk-u-margin-bottom-3`}
                        name={`${filterKey}`}
                        key={nanoid()}
                        onClick={clearSelectedFilter}
                        reverse
                        value={filterValue}
                    >
                        {displayValue}
                        <CloseIcon className={styles.selectedFilterClose} />
                    </Button>
                </div>
            );
            return buttons;
        });
        return selectedButtons;
    };

    const clearSelectedFilter = event => {
        const searchState = { ...search };
        const filteredItems = searchState[event.target.name].filter(
            item => item !== event.target.value
        );
        searchState[event.target.name] = filteredItems;
        if (filteredItems.length === 0) delete searchState[event.target.name];
        else delete searchState[event.target.name][filteredItems];
        clearSearch(searchState);
    };

    return Object.entries(search).map(key => {
        const filterKey = key[0];
        const displayLabel = getDisplayLabelFromSummary(filterKey);
        const displayButtons = generateFilterButtons(filterKey, key[1]);
        return (
            <div key={filterKey}>
                <p className="nhsuk-u-font-size-19 nhsuk-u-margin-bottom-0">{displayLabel}</p>
                {displayButtons}
            </div>
        );
    });
};

export default SelectedFilter;
