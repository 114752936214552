import { LoginView } from './login.view';
import { LoginViewDeprecated } from './login.view-deprecated';
import { selectConfig } from 'store/config.slice';
import { setTitle } from 'util/browser';
import { useAppSelector } from 'app/hooks';
import { useAuthUrls } from './login.hooks';

export const Login = () => {
    setTitle('Login');
    const config = useAppSelector(selectConfig);
    const [loaded, authUrls, sdcsClassicUrl] = useAuthUrls();

    if (!loaded) {
        return null;
    }

    if (!config?.feature_flags?.login_version_two) {
        return <LoginViewDeprecated authUrls={authUrls} sdcsClassicUrl={sdcsClassicUrl} />;
    }

    return <LoginView authUrls={authUrls} sdcsClassicUrl={sdcsClassicUrl} />;
};
