const SubmissionStatusOutput = props => {
    switch (props.value) {
        case 'not-started':
            return 'Not Started';

        case 'in-progress':
            return 'In Progress';

        case 'completed':
            return 'Submitted';

        case 'transferred-in':
            return 'Transferred In';

        case 'transferred-out':
            return 'Transferred Out';

        default:
            return props.value;
    }
};

export default SubmissionStatusOutput;
