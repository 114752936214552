import { FormSectionContainer } from '@form';
import { setTitle } from '@util/browser';
import { Button, Radios } from 'nhsuk-react-components';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useNavigate hook

const MultipleSectionAdd = props => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const params = useParams();

    const [value, setValue] = useState(undefined);
    const [error, setError] = useState(undefined);

    const options = props.sectionSpec?.multiple_sections?.add;

    useEffect(() => {
        setTitle(`${props.sectionSpec.name} - ${props.collection.name}`);
    }, [props.sectionSpec.name, props.collection.name]);

    const handleChange = event => {
        setValue(event.target.value);
        setError(undefined);
    };

    const handleSubmit = event => {
        event.preventDefault();

        if (value === undefined) {
            const error =
                options?.error ??
                `Select yes if you would like to add a ${props.sectionSpec.name.toLowerCase()}`;

            setError(error);
        } else {
            if (value === 'yes') {
                props.onAddMultipleSection({
                    sectionId: props.sectionSpec.id,
                    index: 1
                });
                const nextPath = `/${params.collection}/${params.section}/1`;
                navigate(nextPath); // Use navigate instead of history.push
            } else {
                navigate(props.nextPath); // Use navigate instead of history.push
            }
        }
    };

    const yes = options?.radio_items?.yes ?? {
        display: `Yes, I want to add a ${props.sectionSpec.name.toLowerCase()}`
    };

    const no = options?.radio_items?.no ?? {
        display: `No, I do not want to add a ${props.sectionSpec.name.toLowerCase()}`
    };

    const label = options?.display ?? `Do you want to add a ${props.sectionSpec.name}?`;

    return (
        <FormSectionContainer sectionSpec={props.sectionSpec}>
            <form onSubmit={handleSubmit}>
                <Radios
                    error={error}
                    hint={options?.hint_text}
                    label={label}
                    onChange={handleChange}
                >
                    <Radios.Radio value="yes" hint={yes.hint_text}>
                        {yes.display}
                    </Radios.Radio>
                    <Radios.Radio value="no" hint={no.hint_text}>
                        {no.display}
                    </Radios.Radio>
                </Radios>
                <hr />
                <Button type="submit">Save and Continue</Button>
            </form>
        </FormSectionContainer>
    );
};

export default MultipleSectionAdd;
