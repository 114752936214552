import { PaginationLink } from '@table';
import React from 'react';

const Pagination = ({ handlePageClick, paging }) => {
    const onClick = event => {
        event.preventDefault();
        if (
            !event.target.classList.contains('disabled') &&
            event.target.hasAttribute('data-page-index')
        ) {
            handlePageClick(event.target.getAttribute('data-page-index'));
        }
    };

    const links = [];

    if (paging.currentPage === 1) {
        links.push(<PaginationLink display="Previous" isDisabled="true" />);
    } else {
        links.push(
            <PaginationLink index={paging.currentPage - 1} display="Previous" onClick={onClick} />
        );
    }

    for (let i = 1; i <= paging.numPages; i++) {
        if (i === paging.currentPage) {
            links.push(<PaginationLink index={i} display={i} onClick={onClick} isCurrent="true" />);
        } else {
            links.push(<PaginationLink index={i} display={i} onClick={onClick} />);
        }
    }

    if (paging.currentPage === paging.numPages) {
        links.push(<PaginationLink display="Next" isDisabled="true" />);
    } else {
        links.push(
            <PaginationLink index={paging.currentPage + 1} display="Next" onClick={onClick} />
        );
    }

    return (
        <nav role="navigation" aria-label="Pagination">
            {links.map((link, key) => React.cloneElement(link, { key }))}
        </nav>
    );
};

export default Pagination;
