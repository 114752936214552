import { HintText } from 'nhsuk-react-components';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

const Total = forwardRef((props, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);
    const [value, setValue] = useState(props.question.value ?? '0');

    useEffect(() => {
        if (props.question.from && props.question.to) {
            props.onCopyAnswer({
                from: props.question.from,
                to: props.question.to
            });
        }
        handleCalculationsAndValidation();
        const testquestion = props.questions.forEach(item => {
            if (item.value !== undefined && item.error_text === undefined) {
                validate();
            }
        });
        return testquestion;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.questions]);

    const getQuestionValueById = (questions, questionId) => {
        const question = questions.find(q => q.id === questionId && q.includeInApiPayload === true);
        return question ? question.value : undefined;
    };

    const evalEquationString = equation => {
        const values = equation
            .split(' ')
            .map(item => {
                const questionValue = getQuestionValueById(props.questions, item);
                if (questionValue !== undefined) return Number(questionValue);
                else if (
                    item === '+' ||
                    item === '-' ||
                    item === '*' ||
                    item === '/' ||
                    item === '>=' ||
                    item === '<=' ||
                    item === '<' ||
                    item === '>'
                )
                    return item;
                else if (item === '=') return '===';
                else return '0';
            })
            .join(' ');
        // eslint-disable-next-line no-eval
        return eval(values);
    };

    const validate = () => {
        if (props.question.validate) {
            const validated = evalEquationString(props.question.validate);
            if (validated) {
                setError(undefined);
                setIsValid(true);
            } else {
                setError(true);
                setIsValid(false);
            }
        } else {
            setError(undefined);
            setIsValid(undefined);
        }
    };

    const handleCalculationsAndValidation = () => {
        if (props.question.calculate) {
            const calculatedValue = evalEquationString(props.question.calculate);
            setValue(calculatedValue.toString());
            props.onChange({
                questionId: props.question.id,
                value: calculatedValue.toString()
            });
        } else {
            setValue(props.question.value ?? '0');
        }
    };

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    return (
        <div className={`nhsuk-form-group ${error ? 'nhsuk-form-group--error' : ''}`}>
            <div className="nhsFormGroup">
                <h2 type="total">{`${props.question.name}: ${value}`}</h2>
                <HintText>{props.question.hint_text}</HintText>
                {error && !isValid && props.question.error_text && (
                    // eslint-disable-next-line react/style-prop-object, no-undef
                    <p style={{ color: 'red', fontWeight: 'bold' }}>{props.question.error_text}</p>
                )}
            </div>
        </div>
    );
});

export default Total;
