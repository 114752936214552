import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Draw } from 'leaflet-draw'; // eslint-disable-line
import { Fullscreen } from 'leaflet-fullscreen'; // eslint-disable-line
import L from 'leaflet';
import { getOrganisationLatLng } from '@services/org-code-geo-coder';

const Map = forwardRef((props, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);
    const [setLayer] = useState(null);
    const [setMap] = useState(null);

    const mapContainerRef = useRef(null);

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.question.value]);

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validate = () => {
        if (props.question.mandatory) {
            const value = props.question.value;

            if (!value || value.features.length === 0) {
                setError(`Please draw a catchment area on the map`);
                setIsValid(false);
            } else {
                setError(undefined);
                setIsValid(true);
            }
        } else {
            setError(undefined);
            setIsValid(true);
        }
    };

    const zoomToOrgCode = async map => {
        const latLng = await getOrganisationLatLng(props.orgCode);

        if (latLng) {
            map.setView(latLng, 15);
        }
    };

    const handleMapCreated = map => {
        const value = props.question.value;
        const newLayer = new L.FeatureGroup();

        newLayer.addTo(map);
        setLayer(newLayer);
        setMap(map);

        if (value) {
            const geoJsonLayer = L.geoJSON(value);

            geoJsonLayer.eachLayer(x => {
                newLayer.addLayer(x);
            });

            const bounds = newLayer.getBounds();

            if (bounds.isValid()) {
                map.fitBounds(bounds);
            } else {
                zoomToOrgCode(map);
            }
        } else {
            zoomToOrgCode(map);
        }

        addDrawingControls(map, newLayer);
    };

    const addDrawingControls = (map, layer) => {
        setMapTooltips();

        const options = {
            position: 'topright',
            draw: {
                polyline: false,
                polygon: true,
                circle: false,
                rectangle: false,
                marker: false,
                circlemarker: false
            },
            edit: {
                featureGroup: layer,
                remove: true
            }
        };

        const control = new L.Control.Draw(options);
        map.addControl(control);
        map.addControl(new L.Control.Fullscreen());
        map.on(L.Draw.Event.CREATED, event => handleDrawCreated(event, layer));
        map.on(L.Draw.Event.EDITSTOP, event => handleEditStop(event, layer));
        map.on(L.Draw.Event.DELETESTOP, event => handleDeleteStop(event, layer));
    };

    const setMapTooltips = () => {
        const draw = L.drawLocal.draw;
        const edit = L.drawLocal.edit;

        draw.toolbar.buttons.polygon = 'Draw a catchment area';
        draw.handlers.polygon.tooltip.start = 'Click to start drawing a catchment area';
        draw.handlers.polygon.tooltip.cont = 'Click to continue drawing a catchment area';
        draw.handlers.polygon.tooltip.end = 'Click the first point to complete the catchment area';

        edit.toolbar.buttons.edit = 'Edit a catchment area';
        edit.toolbar.buttons.remove = 'Delete a catchment area';
        edit.handlers.edit.tooltip.text = 'Drag handles or markers to edit a catchment area';
        edit.handlers.remove.tooltip.text = 'Click on a catchment area to remove it';
    };

    const handleDrawCreated = async (event, layer) => {
        layer.addLayer(event.layer);

        await saveChanges(layer);
    };

    const saveChanges = async layer => {
        await props.onChange({
            questionId: props.question.id,
            value: layer.toGeoJSON()
        });

        validate();
    };

    // const handleCopy = async (event, layer) => {
    //     await saveChanges(layer);
    // };

    const handleEditStop = async (event, layer) => {
        await saveChanges(layer);
    };

    const handleDeleteStop = async (event, layer) => {
        await saveChanges(layer);
    };

    const containerStyle = {
        width: '100%',
        height: '40em'
    };

    const centre = {
        lat: 53,
        lng: -2
    };

    return (
        <div className={`nhsuk-form-group ${error ? 'nhsuk-form-group--error' : ''}`}>
            <label className="nhsuk-label">{props.question.name}</label>
            <span className="nhsuk-hint">{props.question.hint_text}</span>

            {error && <span className="nhsuk-error-message">{error}</span>}

            <MapContainer
                ref={mapContainerRef}
                center={centre}
                style={containerStyle}
                whenCreated={handleMapCreated}
                zoom={6}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </MapContainer>
        </div>
    );
});

export default Map;
