import { Idp, selectConfig } from 'store/config.slice';
import { useEffect, useState } from 'react';

import { getUrlSafeSHA256Hash } from 'util/crypto';
import { nanoid } from 'nanoid';
import { useAppSelector } from 'app/hooks';

export function useAuthUrls() {
    const config = useAppSelector(selectConfig);
    const [authUrls, setAuthUrls] = useState(new Map<Idp, string>());
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            const authUrl = new URL('oauth2/authorize', config.auth.serverBaseUrl);
            const redirectUrl = new URL('oauth2/callback', window.location.origin);
            const params = authUrl.searchParams;

            const state = nanoid(50);
            const codeVerifier = nanoid(50);
            const codeVerifierHash = await getUrlSafeSHA256Hash(codeVerifier);

            sessionStorage.setItem('pkce_state', state);
            sessionStorage.setItem('code_verifier', codeVerifier);

            params.set('response_type', 'code');
            params.set('client_id', config.auth.clientId);
            params.set('redirect_uri', redirectUrl.toString());
            params.set('state', state);
            params.set('code_challenge', codeVerifierHash);
            params.set('code_challenge_method', 'S256');

            for (const idp of config.auth.idps) {
                params.set('identity_provider', idp);
                authUrls.set(idp, authUrl.toString());
            }

            setAuthUrls(authUrls);
            setLoaded(true);
        })();
    }, [authUrls, config]);

    return [loaded, authUrls, config.auth.sdcsClassicLoginUrl] as const;
}
