import { errorPageProps } from './error-page.types';
import { setTitle } from 'util/browser';

const ErrorPage = ({ error_description }: errorPageProps) => {
    setTitle('Error');

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-two-thirds">
                <h1>Sorry, there's a problem with this service</h1>
                {error_description ? <p>{error_description}</p> : <p>Please try again later.</p>}
            </div>
        </div>
    );
};

export default ErrorPage;
