import moment from 'moment';

function isOpen(collection) {
    if (collection.submission_window) {
        const openTime = collection.submission_window.open_time;
        const closeTime = collection.submission_window.close_time;

        const open = moment(openTime);
        const close = moment(closeTime);

        const now = moment();

        if (openTime && closeTime) {
            return now.isAfter(open) && now.isBefore(close);
        }

        if (openTime) {
            return now.isAfter(open);
        }

        if (closeTime) {
            return now.isBefore(close);
        }
    }

    return true;
}

export { isOpen };
