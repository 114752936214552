import { CloseIcon } from 'nhsuk-react-components';
import styles from './clear-button.module.scss';

const ClearButton = ({ handleClick, visible }) => {
    if (!visible) {
        return null;
    }

    return (
        <button className={styles.ClearButton} onClick={handleClick}>
            Close
            <CloseIcon width="20" height="20" role="none" />
        </button>
    );
};

export default ClearButton;
