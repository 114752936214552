import { Footer } from 'nhsuk-react-components';
import { Link } from 'react-router-dom';
import React from 'react';

const AppFooter = ({ helpUrl }) => (
    <Footer>
        <Footer.List>
            <Footer.ListItem href="https://digital.nhs.uk/about-nhs-digital">About</Footer.ListItem>
            <Footer.ListItem href="https://digital.nhs.uk/about-nhs-digital/privacy-and-cookies">
                About Privacy and cookies
            </Footer.ListItem>
            <Footer.ListItem href="https://digital.nhs.uk/about-nhs-digital/terms-and-conditions">
                Terms and conditions
            </Footer.ListItem>
            <li className="nhsuk-footer__list-item">
                <Link to="/accessibility" className="nhsuk-footer__list-item-link">
                    Accessibility
                </Link>
            </li>
            {helpUrl && <Footer.ListItem href={helpUrl}>Help with this service</Footer.ListItem>}
        </Footer.List>
        <Footer.Copyright>&copy; Crown copyright</Footer.Copyright>
    </Footer>
);

export default AppFooter;
