import {
    ConfirmTransferSubmission,
    SelectTransferSubmission,
    TransferCompleted
} from '@submission';

import { useState } from 'react';

const TransferSubmission = props => {
    const [transferred, setTransferred] = useState(false);

    return (
        <SelectTransferSubmission
            collection={props.collection}
            data={props.data}
            submissionId={props.submissionId}
            orgCode={props.orgCode}
        >
            {!transferred ? (
                <ConfirmTransferSubmission
                    collection={props.collection}
                    data={props.data}
                    submissionId={props.submissionId}
                    setTransferred={setTransferred}
                />
            ) : (
                <TransferCompleted
                    backHomeHandler={props.backHomeHandler}
                    collection={props.collection}
                />
            )}
        </SelectTransferSubmission>
    );
};

export default TransferSubmission;
