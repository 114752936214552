import { Button } from 'nhsuk-react-components';
import { Idp } from 'store/config.slice';
import { loginProps } from './login.types';

export const LoginViewDeprecated = ({ authUrls, sdcsClassicUrl }: loginProps) => (
    <div className="nhsuk-grid-row">
        <div className="nhsuk-grid-column-full">
            <h1>Login</h1>
            <p>You need to sign in to SDCS Classic to access this service.</p>
            <Button href={sdcsClassicUrl} className="nhsuk-u-margin-right-3">
                Login
            </Button>

            {authUrls.has(Idp.Cognito) && (
                <Button
                    id="local-login"
                    href={authUrls.get(Idp.Cognito)}
                    className="nhsuk-u-margin-right-3"
                >
                    Login with Username and Password
                </Button>
            )}

            {authUrls.has(Idp.Cis2) && (
                <Button href={authUrls.get(Idp.Cis2)} className="nhsuk-u-margin-right-3">
                    Login with CIS2
                </Button>
            )}

            {authUrls.has(Idp.SdcsCloud) && (
                <Button href={authUrls.get(Idp.SdcsCloud)}>Login with SDCS Cloud</Button>
            )}
        </div>
    </div>
);
