import styles from './header-button.module.scss';

const HeaderButton = ({ children, href, target }) => {
    const targetProp = target ? target : '_blank';
    return href ? (
        <div className={`${styles.headerButton}`}>
            <a
                href={href}
                target={targetProp}
                rel="noopener noreferrer"
                className={`${styles.colorWhite}`}
            >
                {children}
            </a>
        </div>
    ) : null;
};

export default HeaderButton;
