import React from 'react';

const OrgNameBanner = props => {
    if (props.showOrgName) {
        return (
            <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-full">
                    <p className="nhsuk-u-font-size-32">{props.orgName}</p>
                </div>
            </div>
        );
    }
    return null;
};

export default OrgNameBanner;
