import { useEffect } from 'react';

const useFilter = (search, setSubmissionData, submissionsDataCache, collectionID) => {
    useEffect(() => {
        if (Object.entries(search).length < 1) {
            sessionStorage.setItem('SubmissionData', null);
            sessionStorage.setItem('search', '{}');

            setSubmissionData(submissionsDataCache);
        } else {
            const getTypeOrValue = value =>
                typeof value === 'string' ? value.toLowerCase() : value;

            const filteredSubmissionData = submissionsDataCache.filter(item => {
                const filterKeys = Object.keys(search);
                return filterKeys.every(key => {
                    if (!search[key].length) return true;
                    return search[key].find(
                        filter => getTypeOrValue(filter) === getTypeOrValue(item[key])
                    );
                });
            });

            setSubmissionData(filteredSubmissionData);
            sessionStorage.setItem('SubmissionData', JSON.stringify(filteredSubmissionData));
            sessionStorage.setItem('search', JSON.stringify(search));
            sessionStorage.setItem('collectionID', collectionID);
        }
    }, [search, setSubmissionData, submissionsDataCache, collectionID]);
};

export default useFilter;
