import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { getCurrentMoment, getMinMoment, getMoment } from '@util/date';

import { DateInput } from 'nhsuk-react-components';

const Date = forwardRef(({ question, onChange }, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);

    const validateDateInput = (dateInput, dateType) => {
        if (/^\d+$/.test(dateInput)) {
            switch (dateType) {
                case 'day':
                    question.value.day = dateInput.slice(0, 2);
                    break;
                case 'month':
                    question.value.month = dateInput.slice(0, 2);
                    break;
                case 'year':
                    question.value.year = dateInput.slice(0, 4);
                    break;
                default:
                    break;
            }
        } else if (/[^0-9]/.test(dateInput)) {
            switch (dateType) {
                case 'day':
                    question.value.day = dateInput.replace(/[^0-9]/g, '');
                    validateDateInput(question.value.day, 'day');
                    break;
                case 'month':
                    question.value.month = dateInput.replace(/[^0-9]/g, '');
                    validateDateInput(question.value.month, 'month');
                    break;
                case 'year':
                    question.value.year = dateInput.replace(/[^0-9]/g, '');
                    validateDateInput(question.value.year, 'year');
                    break;
                default:
                    break;
            }
        }
    };

    const validateDate = () => {
        const moment = getMoment(question.value);
        if (moment.isValid() && question.value.year) {
            setError(undefined);
            setIsValid(true);
            const minDate = question.min_date;
            const maxDate = question.max_date;
            const minAge = question.min_age;
            const questionName = question.name;
            if (minDate && maxDate && !isInDateRange()) {
                setError(`${questionName} must be between ${minDate} and ${maxDate}`);
                setIsValid(false);
            } else if (minDate && !isFutureDate()) {
                setError('Enter a later date for ' + questionName);
                setIsValid(false);
            } else if (maxDate && !isMaxDate()) {
                setError('Enter an earlier date for ' + questionName);
                setIsValid(false);
            } else if (question.only_allow_past_date && !isPastDate()) {
                setError('Future dates are not allowed for ' + questionName);
                setIsValid(false);
            } else if (minAge && !isMinimumAge()) {
                setError('The minimum required age for ' + questionName + ' is ' + minAge);
                setIsValid(false);
            }
        } else {
            setError('Please enter a valid date');
            setIsValid(false);
        }
    };

    const validate = () => {
        if (question.value == null) {
            if (question.mandatory) {
                setError('Please enter a valid date');
                setIsValid(false);
            } else {
                setError(undefined);
                setIsValid(true);
            }
        } else {
            validateDateInput(question.value.day, 'day');
            validateDateInput(question.value.month, 'month');
            validateDateInput(question.value.year, 'year');
            if (question.mandatory) {
                if (question.value.day && question.value.month && question.value.year) {
                    validateDate();
                } else {
                    setError('Enter the ' + question.name);
                    setIsValid(false);
                }
            } else {
                if (question.value.day || question.value.month || question.value.year) {
                    validateDate();
                } else {
                    setError(undefined);
                    setIsValid(true);
                }
            }
        }
    };

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const isPastDate = () => {
        return getMoment(value).isBefore(getCurrentMoment().subtract(1, 'days'));
    };

    const isFutureDate = () => {
        const dateParts = question.min_date.split('-');
        const minDate = getMoment({ day: dateParts[1], month: dateParts[2], year: dateParts[0] });
        return getMoment(value).isAfter(minDate);
    };

    const isMaxDate = () => {
        const dateParts = question.max_date.split('-');
        const maxDate = getMoment({ day: dateParts[1], month: dateParts[2], year: dateParts[0] });
        return getMoment(value).isBefore(maxDate);
    };

    const isInDateRange = () => {
        const maxDateParts = question.max_date.split('-');
        const maxDate = getMoment({
            day: maxDateParts[1],
            month: maxDateParts[2],
            year: maxDateParts[0]
        });

        const minDateParts = question.min_date.split('-');
        const minDate = getMoment({
            day: minDateParts[1],
            month: minDateParts[2],
            year: minDateParts[0]
        });

        return getMoment(question.value).isBetween(minDate, maxDate);
    };

    const isMinimumAge = () => {
        const minBirthdate = getMinMoment(question.min_age);
        return (
            getMoment(question.value).isValid() &&
            getMoment(question.value).isBefore(getCurrentMoment()) &&
            getMoment(question.value).isSameOrBefore(minBirthdate)
        );
    };

    const handleOnChange = async event => {
        const inputName = event.target.id.split('-').slice(-1);
        var date = getValue();
        date[inputName] = event.target.value;
        question.value = date;
        await onChange({
            questionId: question.id,
            value: date
        });

        await validate(date);
    };

    function getValue() {
        return (
            question.value ?? {
                day: '',
                month: '',
                year: ''
            }
        );
    }

    const value = getValue();

    return (
        <DateInput id={question.id} label={question.name} hint={question.hint_text} error={error}>
            <DateInput.Day
                value={value.day}
                onChange={handleOnChange}
                onBlur={handleOnChange}
                required={question.mandatory}
            />
            <DateInput.Month
                value={value.month}
                onChange={handleOnChange}
                onBlur={handleOnChange}
                required={question.mandatory}
            />
            <DateInput.Year
                value={value.year}
                onChange={handleOnChange}
                onBlur={handleOnChange}
                required={question.mandatory}
            />
        </DateInput>
    );
});

export default Date;
