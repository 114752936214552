const FilterToggleButton = ({ handleFilterToggle, isFilterOpen }) => {
    return (
        <button
            onClick={handleFilterToggle}
            className="nhsuk-button nhsuk-button--reverse"
            type="submit"
        >
            {isFilterOpen === true ? ' Hide Filter' : 'Show Filter'}
        </button>
    );
};

export default FilterToggleButton;
