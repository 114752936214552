import './select-transfer-submission.scss';

/* eslint-disable no-restricted-globals */
import { Button, ErrorMessage, Fieldset, HintText, Label } from 'nhsuk-react-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getOrganisationType, searchByPrimaryRoleIdAndName } from 'services/ods-api';

import { Select } from 'react-functional-select';
import { TransferProvider } from 'context/TransferContext';
import { safeAccesFullName } from '@util/transfer-submission';
import { setTitle } from '@util/browser';
import { themeConfig } from 'util/select-theme';
import { useCallbackState } from 'util/hooks';
import { useNavigate } from 'react-router-dom';

export const destinationOptions = {
    PCN: 'pcn',
    GPPRACTICE: 'gppractice'
};

export const userActionHandlers = {
    transfer: confirmTransferHandler => {
        confirmTransferHandler();
    },
    cancel: cancelHandler => cancelHandler()
};

const SelectTransferSubmission = props => {
    setTitle(`Select destination organisation - ${props.collection.name}`);
    const [orgType, setOrgType] = useState('');

    const [destination, setDestination] = useCallbackState(null);
    const [optionsGP, setOptionsGP] = useState([]);
    const [optionsPCN, setOptionsPCN] = useState([]);
    const [error, setError] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const delay = 500;
    const selectRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisibleConfirmTransfer, setIsVisibleConfirmTransfer] = useState(false);
    const history = useNavigate();

    const getOptionValue = useCallback(option => option.OrgId, []);
    const onOptionChange = useCallback(option => {
        setSelectedOption(option);
        if (option) {
            setError('');
        }
    }, []);
    const getOptionLabel = useCallback(option => `${option.Name} (${option.OrgId})`, []);

    const onInputChange = useCallback(() => {
        setIsLoading(true);
    }, []);

    const onInputFocusPCN = useCallback(() => setOptionsPCN([]), []);
    const onInputFocusGP = useCallback(() => setOptionsGP([]), []);

    const onSearchChangeGP = useCallback(async value => {
        try {
            if (value.length > 2) {
                const roleId = 'RO177';
                const response = await searchByPrimaryRoleIdAndName(roleId, value);
                selectRef.current?.clearValue();
                setOptionsGP(response.data.Organisations);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }, []);

    const onSearchChangePCN = useCallback(async value => {
        try {
            if (value.length > 2) {
                const roleId = 'RO272';
                const response = await searchByPrimaryRoleIdAndName(roleId, value);
                selectRef.current?.clearValue();
                setOptionsPCN(response.data.Organisations);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }, []);

    const confirmTransferHandler = () => {
        setIsVisibleConfirmTransfer(true);
    };

    const cancelHandler = () => {
        history(`/${props.collection.url_slug}/submission/${props.submissionId}`);
    };

    useEffect(() => {
        async function fetchData() {
            const response = await getOrganisationType(props.orgCode);
            setOrgType(response);
            if (orgType === 'RO116') {
                setDestination(destinationOptions.GPPRACTICE);
                setSelectedOption(null);
                setOptionsGP([]);
                setError('');
            } else {
                setSelectedOption(null);
                setDestination(destinationOptions.PCN);
                setOptionsPCN([]);
                setError('');
            }
        }
        fetchData();
    }, [orgType, props.orgCode, setDestination, setSelectedOption, setOptionsGP, setError]);

    const selectPCN = (
        <Select
            isClearable
            options={optionsPCN}
            themeConfig={themeConfig}
            ref={selectRef}
            onInputChange={onInputChange}
            inputDelay={delay}
            isLoading={isLoading}
            onSearchChange={onSearchChangePCN}
            onInputFocus={onInputFocusPCN}
            placeholder=""
            noOptionsMsg=""
            onOptionChange={onOptionChange}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            error={error}
        />
    );

    const selectGP = (
        <Select
            isClearable
            options={optionsGP}
            themeConfig={themeConfig}
            ref={selectRef}
            inputDelay={delay}
            isLoading={isLoading}
            onSearchChange={onSearchChangeGP}
            onInputFocus={onInputFocusGP}
            placeholder=""
            noOptionsMsg=""
            onOptionChange={onOptionChange}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            error={error}
        />
    );

    if (!isVisibleConfirmTransfer) {
        return (
            <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-full">
                    <h1>Transfer Person</h1>
                    <Fieldset>
                        <Fieldset.Legend>
                            <div>
                                <Label>Name of Staff</Label>
                                <Label>{safeAccesFullName(props.data)}</Label>
                            </div>
                        </Fieldset.Legend>

                        <Fieldset.Legend>
                            <div className="nhsuk-u-margin-top-5" error={error}>
                                <Label>Destination Organisation</Label>
                                <ErrorMessage>{error}</ErrorMessage>
                                <HintText>
                                    Search for an organisation to transfer this person into
                                </HintText>

                                {orgType === 'RO177' || orgType === 'RO116' ? selectGP : selectPCN}
                            </div>
                        </Fieldset.Legend>
                    </Fieldset>
                    <>
                        <div className="nhsuk-u-margin-top-3">
                            <span>
                                <Button
                                    className="nhsuk-button nhsuk-button--primary"
                                    aria-disabled="false"
                                    type="submit"
                                    onClick={event => {
                                        if (!destination) {
                                            setError('Choose the organisation type');
                                            return;
                                        }
                                        if (!selectedOption) {
                                            setError('Select the Destination Organisation');
                                            return;
                                        }
                                        userActionHandlers.transfer(confirmTransferHandler);
                                    }}
                                >
                                    Transfer
                                </Button>
                            </span>
                            <span className="nhsuk-u-padding-left-3">
                                <Button
                                    className="nhsuk-button nhsuk-button--secondary"
                                    aria-disabled="false"
                                    type="submit"
                                    onClick={() => userActionHandlers.cancel(cancelHandler)}
                                >
                                    Cancel
                                </Button>
                            </span>
                        </div>
                    </>
                </div>
            </div>
        );
    } else {
        return <TransferProvider value={selectedOption}>{props.children}</TransferProvider>;
    }
};

export default SelectTransferSubmission;
