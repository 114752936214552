import {
    BranchSiteOutput,
    CheckboxOutput,
    DateOutput,
    MapOutput,
    NHSNumberOutput,
    NINumberOutput,
    OdsLookupOutput,
    OpeningTimesOutput,
    RadioOutput,
    SelectOutput
} from '@output';
import { Link, useParams } from 'react-router-dom';

import { SummaryList } from 'nhsuk-react-components';
import { scrollToTopOfPage } from '@util/browser';

const FormSummarySection = props => {
    const params = useParams();

    const section = props.section;
    const collection = params.collection;
    const questions = section.questions;

    const changeUrl = section.allow_multiple
        ? `/${collection}/${section.id}/${section.multipleSectionIndex}`
        : `/${collection}/${section.id}`;

    const handleChangeClick = event => {
        if (props.onChangeClick) {
            props.onChangeClick(event);
        }

        scrollToTopOfPage();
    };

    function enrichedQuestionName(question) {
        if (question.newQuestion) {
            return (
                <div>
                    <span style={{ color: '#005EB8' }}>
                        <b>[New Question]</b>
                        <br />
                    </span>{' '}
                    {question.name}
                </div>
            );
        }
        if (question.revisedQuestion) {
            return (
                <div>
                    <span style={{ color: '#005EB8' }}>
                        <b>[Revised Question]</b>
                        <br />
                    </span>{' '}
                    {question.name}
                </div>
            );
        }
        return question.name;
    }

    return (
        <div>
            <h2>{props.section.name}</h2>
            <SummaryList>
                {questions
                    .filter(question => question.showOnSummaryPage)
                    .map(question => (
                        <SummaryList.Row key={question.id}>
                            <SummaryList.Key>{enrichedQuestionName(question)}</SummaryList.Key>
                            <SummaryList.Value>
                                {{
                                    'branch-site': <BranchSiteOutput question={question} />,
                                    checkbox: <CheckboxOutput question={question} />,
                                    map: <MapOutput question={question} orgCode={props.orgCode} />,
                                    date: <DateOutput question={question} format="long" />,
                                    'nhs-number': <NHSNumberOutput question={question} />,
                                    'open-close-times': <OpeningTimesOutput question={question} />,
                                    radio: <RadioOutput question={question} />,
                                    select: <SelectOutput question={question} />,
                                    'ni-number': <NINumberOutput question={question} />,
                                    'ods-lookup': <OdsLookupOutput question={question} />
                                }[question.type] || question.value}
                            </SummaryList.Value>
                            <SummaryList.Actions>
                                {props.showChangeLink && !question.prevent_change_after_save && (
                                    <Link
                                        to={changeUrl}
                                        onClick={handleChangeClick}
                                        aria-label={`Change the ${question.name}`}
                                    >
                                        Change
                                    </Link>
                                )}
                            </SummaryList.Actions>
                        </SummaryList.Row>
                    ))}
            </SummaryList>
        </div>
    );
};

export default FormSummarySection;
