import { ChevronLeftIcon } from 'nhsuk-react-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const BackLink = forwardRef(({ to, text, backClick }, ref) => {
    const handleChangeClick = event => {
        if (backClick) {
            backClick(event);
        }
    };

    return (
        <div className="nhsuk-back-link">
            <Link className="nhsuk-back-link__link" to={to} onClick={handleChangeClick} ref={ref}>
                <ChevronLeftIcon />
                {text}
            </Link>
        </div>
    );
});

BackLink.propTypes = {
    /**
   The url to navigate to
  */
    to: PropTypes.string.isRequired,
    /**
   The button label
  */
    text: PropTypes.string,
    /**
   Function to call on back button click
  */
    backClick: PropTypes.func
};

BackLink.defaultProps = {
    to: '/',
    text: 'Back',
    backClick: null
};

export default BackLink;
