import React, { useEffect, useState } from 'react';

import { Table } from 'nhsuk-react-components';
import { TableSortHeader } from '@table';
import { nanoid } from 'nanoid';

const TableHeader = ({
    columnSpecs,
    isSubmissionWindowOpen,
    showViewColumn,
    actionColumnText,
    sortSubmissions,
    isHideEdit
}) => {
    const [sortField, setSortField] = useState('created_at');
    const [sortDirection, setSortDirection] = useState('desc');

    useEffect(() => {
        sortSubmissions(sortField, sortDirection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortDirection]);

    const setSort = headerId => {
        if (headerId === sortField) {
            sortDirection === 'asc' ? setSortDirection('desc') : setSortDirection('asc');
        } else {
            setSortField(headerId);
            setSortDirection('asc');
        }
    };

    const displayHeader = () => {
        return columnSpecs.map(columnSpec => {
            return (
                <Table.Cell key={nanoid()}>
                    <TableSortHeader
                        columnId={columnSpec.id}
                        display={columnSpec.display}
                        setSort={setSort}
                        sortDirection={sortDirection}
                        sortField={sortField}
                    />
                </Table.Cell>
            );
        });
    };

    return (
        <Table.Head>
            <Table.Row>
                {displayHeader()}
                {showViewColumn && <Table.Cell>{actionColumnText}</Table.Cell>}
                {isSubmissionWindowOpen && !isHideEdit && (
                    <Table.Cell>{actionColumnText}</Table.Cell>
                )}
            </Table.Row>
        </Table.Head>
    );
};

export default TableHeader;
