import React, { useEffect, useState } from 'react';

import { Breadcrumb } from 'nhsuk-react-components';
import { Link } from 'react-router-dom';
import styles from './app-breadcrumb.module.scss';
import { useLocation } from 'react-router-dom';

const AppBreadcrumb = ({ collection, setHome, setOrganisation, setCollection }) => {
    const collectionUrlSlug = collection?.url_slug;
    const collectionName = collection?.name;
    const { pathname } = useLocation();
    const [isCollection, setIsCollection] = useState(false);

    useEffect(() => {
        const isCollectionPath =
            collectionUrlSlug !== '' && pathname?.split('/')?.[1] === `${collectionUrlSlug}`;
        setIsCollection(isCollectionPath);
    }, [collectionUrlSlug, pathname]);

    const handleHomeLink = () => {
        setHome({});
        setCollection({});
        setOrganisation({});
    };
    return (
        <Breadcrumb className={`${styles.appBreadcrumb}`}>
            <Breadcrumb.Item to="/" asElement={Link} onClick={handleHomeLink}>
                Home
            </Breadcrumb.Item>
            {isCollection && (
                <Breadcrumb.Item to={`/${collectionUrlSlug}`} asElement={Link}>
                    {collectionName}
                </Breadcrumb.Item>
            )}
        </Breadcrumb>
    );
};

export default AppBreadcrumb;
