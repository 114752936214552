import {
    BranchSiteOutput,
    CheckboxOutput,
    DateOutput,
    NHSNumberOutput,
    NINumberOutput,
    RadioOutput,
    SelectOutput,
    SubmissionIdOutput,
    SubmissionStatusOutput
} from '@output';
import { Link, useParams } from 'react-router-dom';
import { Table, Tag } from 'nhsuk-react-components';

import React from 'react';

const CollectionSummaryTableCell = ({
    submission,
    collection,
    value,
    columnSpec,
    enableViewSubmissionPage,
    onViewClick
}) => {
    const match = useParams();
    const getViewSubmissionUrl = `/${match.collection}/submission/${submission.id}`;
    return (
        <Table.Cell>
            {{
                id: (
                    <>
                        {!enableViewSubmissionPage || submission.status === 'not-started' ? (
                            <SubmissionIdOutput value={value} />
                        ) : (
                            <Link to={getViewSubmissionUrl} onClick={() => onViewClick(submission)}>
                                <SubmissionIdOutput value={value} />
                            </Link>
                        )}
                    </>
                ),

                date: <DateOutput isoString={value} format="short" />,
                select: <SelectOutput value={value} question={columnSpec} />,
                status: <SubmissionStatusOutput value={submission.status} />,
                'branch-site': <BranchSiteOutput value={value} />,
                org_name: <>{submission.org_name}</>,
                'nhs-number': <NHSNumberOutput value={value} />,
                'ni-number': <NINumberOutput value={value} />,
                checkbox: <CheckboxOutput question={columnSpec} value={value} />,
                radio: <RadioOutput question={columnSpec} value={value} />,

                actions: (
                    <>
                        {submission.status === 'transferred-in' && (
                            <Tag color="blue" className="nhsuk-u-margin-left-1">
                                transferred in
                            </Tag>
                        )}

                        {submission.status === 'transferred-out' && (
                            <Tag color="blue" className="nhsuk-u-margin-left-1">
                                transferred out
                            </Tag>
                        )}

                        {submission.dq_warning && (
                            <Tag color="orange" className="nhsuk-u-margin-left-1">
                                incomplete
                            </Tag>
                        )}
                    </>
                )
            }[columnSpec.type] || value}
        </Table.Cell>
    );
};

export default CollectionSummaryTableCell;
