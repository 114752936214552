import { ErrorMessage, HintText, Label, TextInput } from 'nhsuk-react-components';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { nanoid } from 'nanoid';

const Time = forwardRef((props, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [second, setSecond] = useState('');

    // useEffect(() => {
    //     validate();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [hour, minute, second]);

    const validate = () => {
        const { mandatory, name } = props.question;
        let isValid = true;

        if (mandatory && (!hour || !minute || !second)) {
            setError(`Enter the ${name}`);
            isValid = false;
        } else if ((hour || minute || second) && !validateTime()) {
            setError('Please enter a valid time');
            isValid = false;
        } else {
            setError(undefined);
        }

        setIsValid(isValid);
    };

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validateTime = () => {
        return (
            !isNaN(parseInt(hour)) &&
            !isNaN(parseInt(minute)) &&
            !isNaN(parseInt(second)) &&
            hour >= 0 &&
            hour <= 23 &&
            minute >= 0 &&
            minute <= 59 &&
            second >= 0 &&
            second <= 59
        );
    };

    const handleOnChange = async event => {
        const { id, value } = event.target;
        const inputName = id.split('-')[0];

        switch (inputName) {
            case 'hour':
                setHour(value);
                break;
            case 'minute':
                setMinute(value);
                break;
            case 'second':
                setSecond(value);
                break;
            default:
                break;
        }

        await props.onChange({
            questionId: props.question.id,
            value: hour && minute && second ? `${hour}:${minute}:${second}` : undefined
        });

        validate();
    };

    const { name, hint_text, mandatory } = props.question;

    return (
        <div className={`nhsuk-form-group ${error ? 'nhsuk-form-group--error' : ''}`}>
            <Label>{name}</Label>
            <HintText>{hint_text}</HintText>
            <ErrorMessage>{error}</ErrorMessage>
            <div className="nhsuk-date-input">
                <div className="nhsuk-date-input__item">
                    <TextInput
                        id={`hour-${nanoid()}`}
                        label="Hour"
                        type="number"
                        width="2"
                        min="0"
                        max="23"
                        error={!!error}
                        value={hour}
                        onChange={handleOnChange}
                        onBlur={handleOnChange}
                        required={mandatory}
                    />
                </div>
                <div className="nhsuk-date-input__item">
                    <TextInput
                        id={`minute-${nanoid()}`}
                        label="Minute"
                        type="number"
                        width="2"
                        min="0"
                        max="59"
                        error={!!error}
                        value={minute}
                        onChange={handleOnChange}
                        onBlur={handleOnChange}
                        required={mandatory}
                    />
                </div>
                <div className="nhsuk-date-input__item">
                    <TextInput
                        id={`second-${nanoid()}`}
                        label="Second"
                        type="number"
                        width="2"
                        min="0"
                        max="59"
                        error={!!error}
                        value={second}
                        onChange={handleOnChange}
                        onBlur={handleOnChange}
                        required={mandatory}
                    />
                </div>
            </div>
        </div>
    );
});

export default Time;
