import { ChevronLeftIcon } from 'nhsuk-react-components';
import React from 'react';
import styles from './table-sort-header.module.css';

const TableSortHeader = ({ columnId, display, setSort, sortDirection, sortField }) => {
    const showArrow = () => {
        if (sortField === columnId) {
            return sortDirection === 'asc' ? (
                <span className={styles.asc}>
                    <ChevronLeftIcon className={styles.arrow} />
                </span>
            ) : (
                <span className={styles.desc}>
                    <ChevronLeftIcon className={styles.arrow} />
                </span>
            );
        } else {
            return (
                <div className={styles.none}>
                    <ChevronLeftIcon className={styles.arrow} />
                    <ChevronLeftIcon className={styles.arrow} />
                </div>
            );
        }
    };

    return (
        <div className={styles.tableSortHeader}>
            <button
                className={`${styles.button} nhsuk-u-padding-right-3`}
                id={columnId}
                onClick={event => {
                    setSort(event.currentTarget.id);
                }}
            >
                {display}
                {showArrow()}
            </button>
        </div>
    );
};

export default TableSortHeader;
