import axios from 'axios';

const ODSOrganisationsEndpointUrl =
    'https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations';

function getOrganisation(orgCode) {
    return axios
        .get(`https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations/${orgCode}`)
        .then(response => {
            if (response?.status === 200) {
                return response?.data?.Organisation;
            }
            return undefined;
        })
        .catch(error => {});
}

async function getOrganisationPostCode(orgCode) {
    const organisation = await getOrganisation(orgCode);

    return organisation?.GeoLoc?.Location?.PostCode;
}

async function getBranchSites(orgCode) {
    const response = await axios.get(
        `https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations?RelTypeId=RE6&TargetOrgId=${orgCode}`
    );

    return response.data.Organisations;
}

const searchByPrimaryRoleIdAndName = (roleId, name) => {
    return axios.get(`${ODSOrganisationsEndpointUrl}?PrimaryRoleId=${roleId}&Name=${name}`);
};

async function getOrganisationType(orgCode) {
    const response = await axios.get(
        `https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations/${orgCode}`
    );

    const roles = response.data.Organisation.Roles.Role;
    const primaryRole = roles.find(role => role.primaryRole === true);
    const primaryRoleId = primaryRole ? primaryRole.id : roles[0].id;

    return primaryRoleId;
}

export {
    getOrganisation,
    getOrganisationPostCode,
    getBranchSites,
    searchByPrimaryRoleIdAndName,
    getOrganisationType
};
