import { ErrorSummary } from 'nhsuk-react-components';
import { Link } from 'react-router-dom';

function createPath(collection, id, multipleSectionIndex = null) {
    if (multipleSectionIndex) {
        return `/${collection}/${id}/${multipleSectionIndex}`;
    }
    return `/${collection}/${id}`;
}

const MissingMandatoryAnswersErrorSummary = ({ missingMandatoryAnswers, collection }) => {
    return (
        <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
            <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
            <ErrorSummary.Body>
                <p>
                    You must answer the following questions before submitting.
                    <br />
                    Click on the question name to go to the correct page.
                </p>
                <ErrorSummary.List className="margaret">
                    {missingMandatoryAnswers.map(({ id, questions, multipleSectionIndex }) =>
                        questions.map(({ name }) => (
                            <li key={name}>
                                <Link
                                    to={{
                                        pathname: createPath(collection, id, multipleSectionIndex)
                                    }}
                                >
                                    {name}
                                </Link>
                            </li>
                        ))
                    )}
                </ErrorSummary.List>
            </ErrorSummary.Body>
        </ErrorSummary>
    );
};

export default MissingMandatoryAnswersErrorSummary;
