import { ViewConfirmDeletions, ViewDeleteReasons } from '@submission';

const DeleteSubmission = props => {
    return (
        <ViewDeleteReasons collection={props.collection} submissionId={props.submissionId}>
            <ViewConfirmDeletions
                backHomeHandler={props.backHomeHandler}
                collection={props.collection}
                submissionId={props.submissionId}
            />
        </ViewDeleteReasons>
    );
};

export default DeleteSubmission;
