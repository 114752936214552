const setGender = gender => {
    switch (gender.toLowerCase()) {
        case 'm':
            gender = 'Male';
            break;
        case 'w':
            gender = 'Female';
            break;
        default:
            break;
    }
    return gender;
};

export default setGender;
