const SubmissionIdOutput = props => {
    const value = props.value;
    let formattedOutput = '';
    for (let i = 0; i < value.length; i++) {
        if (i > 0 && i % 5 === 0) {
            formattedOutput += '-';
        }
        formattedOutput += value.charAt(i);
    }

    return formattedOutput;
};

export default SubmissionIdOutput;
