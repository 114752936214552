import React, { useEffect, useState } from 'react';

import Api from '@services/api';
import CollectionSwitcher from '../collection-switcher/collection-switcher';
import { NoPermissions } from '../no-permissions/no-permissions';
import OrgSwitcher from '../org-switcher/org-switcher';
import { isEmpty } from 'lodash';
import { setTitle } from '@util/browser';

const Home = ({ setCollection, collection, setOrganisation, organisation, home }) => {
    const [permissions, setPermissions] = useState([]);
    const [render, setRender] = useState('loading');

    useEffect(() => {
        async function fetchOrgData() {
            const api = new Api();
            const response = await api.getUserOrgCollection();
            const orgs = response.data.orgs;
            setPermissions(orgs);

            if (orgs.length === 0) {
                setRender('no_permissions');
                return;
            }

            if (isEmpty(home)) setRender('org');
        }
        fetchOrgData();
    }, [home]);

    setTitle('Home');
    const handleSetCollectionRender = () => {
        setRender('collection');
    };

    const orgSwitcher = () => (
        <OrgSwitcher
            setOrganisation={setOrganisation}
            organisation={organisation}
            handleSetCollectionRender={handleSetCollectionRender}
            permissions={permissions}
        />
    );

    const collectionSwitcher = () => (
        <CollectionSwitcher
            organisation={organisation}
            collection={collection}
            setCollection={setCollection}
            permissions={permissions}
        />
    );

    return render === 'loading' ? (
        'Loading...'
    ) : (
        <>
            <div className="nhsuk-grid-column">
                <h1>NHS England Forms Collections</h1>
            </div>
            {{
                collection: collectionSwitcher,
                org: orgSwitcher,
                no_permissions: NoPermissions,
                default: orgSwitcher
            }[render || 'default']()}
        </>
    );
};

export default Home;
