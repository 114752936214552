import { Checkboxes } from 'nhsuk-react-components';

const FilterByCheckbox = ({ submissionSummary }) => {
    return (
        <form>
            {submissionSummary
                .filter(question => question.type === 'radio' || question.type === 'select')
                .map(question => (
                    <Checkboxes
                        question={question.id}
                        id={question.id}
                        label={question.display}
                        key={question.id}
                    >
                        {question.options.map((option, index) => (
                            <Checkboxes.Box key={index} value={option.key} onChange={() => {}}>
                                {option.value}
                            </Checkboxes.Box>
                        ))}

                        {/* {question.options.map(option => (
                            <div key={option.key} className="nhsuk-checkboxes__item">
                                <input
                                    type="checkbox"
                                    name={question.id}
                                    onChange={() => {}}
                                    value={option.key}
                                    className="nhsuk-checkboxes__input"
                                />
                                <label htmlFor={question.id} className="nhsuk-checkboxes__label">
                                    {option.value}
                                </label>
                            </div>
                        ))} */}
                    </Checkboxes>
                ))}
        </form>
    );
};

export default FilterByCheckbox;
