import axios from 'axios';

const requestCache = {};

const makeRequestCreator = () => {
    let token;

    return async (query, accessToken) => {
        if (token) {
            token.cancel();
        }

        token = axios.CancelToken.source();

        try {
            if (requestCache[query]) {
                return requestCache[query];
            }

            const res = await axios({
                method: 'get',
                url: query,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                cancelToken: token.token
            });

            const result = res;
            requestCache[query] = result;
            return result;
        } catch (error) {
            if (axios.isCancel(error)) {
                return { 'Request cancelled': error.message };
            } else {
                return { 'Something went wrong': error.message };
            }
        }
    };
};

export const gmcSearch = makeRequestCreator();
