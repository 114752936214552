import { Button, Radios } from 'nhsuk-react-components';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormSectionContainer } from '@form';

const MultipleSectionAddAnother = props => {
    const [value, setValue] = useState(undefined);
    const [error, setError] = useState(undefined);
    const params = useParams();
    const navigate = useNavigate();
    const options = props.sectionSpec?.multiple_sections?.add_another;

    const handleChange = event => {
        setValue(event.target.value);
        setError(undefined);
    };

    const handleSubmit = event => {
        event.preventDefault();

        if (value === undefined) {
            const errorMsg =
                options?.error ??
                `Select yes if you would like to add another ${props.sectionSpec.name.toLowerCase()}`;

            setError(errorMsg);
        } else {
            let nextPath = props.nextPath;
            if (props.sectionSpec?.multiple_sections?.enable_skip_to_summary) {
                nextPath = `/${params.collection}/summary`;
            }

            if (value === 'yes') {
                let index = Number(params.index);
                index++;

                nextPath = `/${params.collection}/${params.section}/${index}`;

                props.onAddMultipleSection({
                    sectionId: props.sectionSpec.id,
                    index: index
                });
            }

            navigate(nextPath);
        }
    };

    const yes = options?.radio_items?.yes ?? {
        display: `Yes, I want to add another ${props.sectionSpec.name.toLowerCase()}`
    };

    const no = options?.radio_items?.no ?? {
        display: `No, I do not want to add another ${props.sectionSpec.name.toLowerCase()}`
    };

    const label = options?.display ?? `Do you want to add another ${props.sectionSpec.name}?`;

    return (
        <FormSectionContainer sectionSpec={props.sectionSpec}>
            <form onSubmit={handleSubmit}>
                <Radios
                    error={error}
                    hint={options?.hint_text}
                    label={label}
                    onChange={handleChange}
                >
                    <Radios.Radio value="yes" hint={yes.hint_text}>
                        {yes.display}
                    </Radios.Radio>
                    <Radios.Radio value="no" hint={no.hint_text}>
                        {no.display}
                    </Radios.Radio>
                </Radios>
                <hr />
                <Button type="submit">Save and Continue</Button>
            </form>
        </FormSectionContainer>
    );
};

export default MultipleSectionAddAnother;
