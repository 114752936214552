import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { BranchSiteOutput } from '@output';
import { Select as NhsUkSelect } from 'nhsuk-react-components';
import { getBranchSites } from '@services/ods-api';

const BranchSite = forwardRef((props, ref) => {
    const [branchSites, setBranchSites] = useState([]);
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);

    useEffect(() => {
        const fetchData = async () => {
            if (props.allowChanges) {
                try {
                    const fetchedBranchSites = await getBranchSites(props.orgCode);
                    const mappedBranchSites = fetchedBranchSites.map(x => ({
                        org_code: x.OrgId,
                        org_name: x.Name
                    }));
                    setBranchSites(mappedBranchSites);
                } catch (error) {
                    // console.error('Error fetching branch sites:', error);
                    // Handle error state if needed
                }
            }
        };

        fetchData();
    }, [props.allowChanges, props.orgCode]);

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validate = () => {
        if (props.question.mandatory) {
            const value = getValue();

            if (!value) {
                setError(`Enter the ${props.question.name}`);
                setIsValid(false);
            } else {
                setError(undefined);
                setIsValid(true);
            }
        } else {
            setError(undefined);
            setIsValid(true);
        }
    };

    const getValue = () => {
        return props.question.value;
    };

    const handleChange = async event => {
        const value = branchSites.find(x => x.org_code === event.target.value);

        await props.onChange({
            questionId: props.question.id,
            value: value
        });

        validate();
    };

    if (props.allowChanges) {
        const value = getValue();
        const selectedOrgCode = value ? value.org_code : '';

        return (
            <NhsUkSelect
                label={props.question.name}
                hint={props.question.hint_text}
                value={selectedOrgCode}
                onChange={handleChange}
                onBlur={handleChange}
                error={error}
                required={props.question.mandatory}
            >
                <NhsUkSelect.Option value="">Please Select</NhsUkSelect.Option>
                {branchSites.map(branchSite => (
                    <NhsUkSelect.Option key={branchSite.org_code} value={branchSite.org_code}>
                        {branchSite.org_name} ({branchSite.org_code})
                    </NhsUkSelect.Option>
                ))}
            </NhsUkSelect>
        );
    } else {
        return (
            <div className="nhsuk-form-group">
                <p className="nhsuk-label">{props.question.name}</p>
                <p>
                    <BranchSiteOutput question={props.question} />
                </p>
            </div>
        );
    }
});

export default BranchSite;
