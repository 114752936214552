import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import authReducer from 'store/auth.slice';
import configReducer from 'store/config.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        config: configReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
