import styles from './warning-summary.module.scss';

const WarningSummary = ({ ariaLabelledby, role, tabIndex, children }) => (
    <div
        className={styles.warningSummary}
        aria-labelledby={ariaLabelledby}
        role={role}
        tabIndex={tabIndex}
    >
        {children}
    </div>
);
WarningSummary.Title = ({ id, children }) => (
    <h2 id={id} className={styles.warningSummaryTitle}>
        {children}
    </h2>
);
WarningSummary.Body = ({ children }) => <div className={styles.warningSummaryBody}>{children}</div>;
WarningSummary.List = ({ children }) => <ul className={styles.warningSummaryList}>{children}</ul>;
WarningSummary.Item = ({ href, children }) => <li>{children}</li>;

export default WarningSummary;
